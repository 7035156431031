import { useGoogleAnalytics } from 'helpers/useGoogleAnalytics';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import PrivateRoute from 'ui/design-base/PrivateRoute';
import { Spinner } from '@radix-ui/themes';

const PlayerMatchesPage = lazy(() => import('pages/design-base/PlayerMatchesPage'));
const PlayerStatsPage = lazy(() => import('pages/design-base/PlayerStatsPage'));
const PlayerSocialPage = lazy(() => import('pages/design-base/PlayerSocialPage'));
const PlayerProfilePage = lazy(() => import('pages/design-base/PlayerProfilePage'));
const NotificationsPage = lazy(() => import('pages/design-base/NotificationsPage'));
const ProfilePage = lazy(() => import('pages/design-base/ProfilePage'));
const NotFoundPage = lazy(() => import('pages/design-base/NotFoundPage'));
const RedirectOldPages = lazy(() => import('pages/design-base/RedirectOldPages'));
const PostPage = lazy(() => import('pages/design-base/SinglePostPage'));
const UserPage = lazy(() => import('pages/design-base/UserPage'));
const UserSocialPage = lazy(() => import('pages/design-base/UserSocialPage'));
const ClubPage = lazy(() => import('pages/design-base/ClubPage'));
const ClubMembersPage = lazy(() => import('pages/design-base/ClubMembersPage'));
const ClubCompetitionsPage = lazy(() => import('pages/design-base/ClubCompetitionsPage'));
const ClubSocialPage = lazy(() => import('pages/design-base/ClubSocialPage'));
const DrawPage = lazy(() => import('pages/design-base/DrawPage'));
const CompetitionPage = lazy(() => import('pages/design-base/CompetitionPage'));
const TournamentPage = lazy(() => import('pages/design-base/TournamentPage'));
const SearchResultsPage = lazy(() => import('pages/design-base/SearchResultsPage'));
const PlayersAdvancedSearchPage = lazy(() => import('pages/design-base/PlayersAdvancedSearchPage'));
const CompetitionAdvancedSearchPage = lazy(() => import('pages/design-base/CompetitionAdvancedSearchPage'));

const RequestPasswordResetPage = lazy(() => import('pages/design-base/RequestPasswordResetPage'));
const LoginPage = lazy(() => import('pages/design-base/LoginPage'));
const RegisterPage = lazy(() => import('pages/design-base/RegisterPage'));
const FaqPage = lazy(() => import('pages/design-base/FaqPage'));
const HomePage = lazy(() => import('pages/design-base/HomePage'));
const AboutUsPage = lazy(() => import('pages/design-base/AboutUsPage'));
const TermsOfUsePage = lazy(() => import('pages/design-base/TermsOfUsePage'));
const PrivacyPolicyPage = lazy(() => import('pages/design-base/PrivacyPolicyPage'));
// const PartnerPage = lazy(() => import('pages/design-base/PartnerPage'));
const PremiumPage = lazy(() => import('pages/design-base/PremiumPage'));
const PremiumizePage = lazy(() => import('pages/design-base/PremiumizePage'));
const ShopProfilePage = lazy(() => import('pages/design-base/ShopProfilePage'));
const DrawNewMatchPage = lazy(() => import('pages/design-base/DrawNewMatchPage'));
const ContactUsPage = lazy(() => import('pages/design-base/ContactUsPage'));
const ProfileMessagesPage = lazy(() => import('pages/design-base/ProfileMessagesPage'));
const ConfirmMatchesPage = lazy(() => import('pages/design-base/ConfirmMatchesPage'));
const AdditionalMatchesPage = lazy(() => import('pages/design-base/AdditionalMatchesPage'));
const EditProfilePage = lazy(() => import('pages/design-base/EditProfilePage'));
const PasswordResetPage = lazy(() => import('pages/design-base/PasswordResetPage'));
const RegistrationCompletedPage = lazy(() => import('pages/design-base/RegistrationCompletedPage'));
const CompleteProfilePage = lazy(() => import('pages/design-base/CompleteProfilePage'));
const AdditionalInformationPage = lazy(() => import('pages/design-base/AdditionalInformationPage'));
const AccountConfirmationPage = lazy(() => import('pages/design-base/AccountConfirmationPage'));
const FeedPage = lazy(() => import('pages/design-base/FeedPage'));

const SentryRoutes = Routes; //TODO needed to enable the sentry tracing with RR used as library withSentryReactRouterV7Routing(Routes);

export const RoutesDefinition = () => {
  useGoogleAnalytics();

  return (
    <Suspense fallback={<Spinner />}>
      <SentryRoutes>
        <Route path='/' element={<HomePage />} />
        <Route path='/index.php' element={<RedirectOldPages />} />

        <Route path='/cerca' element={<SearchResultsPage />} />
        <Route path='/accedi' element={<LoginPage />} />

        <Route path='/registrati' element={<RegisterPage />} />
        <Route path='/conferma-account/:token' element={<AccountConfirmationPage />} />
        <Route path='/registrati/informazioni-aggiuntive/:token' element={<AdditionalInformationPage />} />
        <Route path='/registrati/completa-profilo/:token' element={<CompleteProfilePage />} />
        <Route path='/registrati/fine/:token' element={<RegistrationCompletedPage />} />

        <Route path='/reimposta-password/:token' element={<PasswordResetPage />} />
        <Route path='/password-dimenticata' element={<RequestPasswordResetPage />} />

        <Route
          path='/feed'
          element={
            <PrivateRoute>
              <FeedPage />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path='/profilo/social'
          element={
            <PrivateRoute>
              <PlayerSocialPage />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path='/profilo/modifica'
          element={
            <PrivateRoute>
              <EditProfilePage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/profilo/partite'
          element={
            <PrivateRoute>
              <PlayerMatchesPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/profilo/statistiche'
          element={
            <PrivateRoute>
              <PlayerStatsPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/profilo/partite-aggiuntive'
          element={
            <PrivateRoute>
              <AdditionalMatchesPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/profilo/conferma-partite-aggiuntive'
          element={
            <PrivateRoute>
              <ConfirmMatchesPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/profilo/messaggi'
          element={
            <PrivateRoute>
              <ProfileMessagesPage />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path='/profilo'
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        ></Route>

        <Route path='/paginarisultati.php' element={<RedirectOldPages />} />

        <Route
          path='/giocatore/:id/partite'
          element={
            <PrivateRoute>
              <PlayerMatchesPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/giocatore/:id/statistiche'
          element={
            <PrivateRoute>
              <PlayerStatsPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/giocatore/:id/social'
          element={
            <PrivateRoute>
              <PlayerSocialPage />
            </PrivateRoute>
          }
        ></Route>
        <Route path='/giocatore/:id' element={<PlayerProfilePage />} />

        <Route
          path='/utente/:id/social'
          element={
            <PrivateRoute>
              <UserSocialPage />
            </PrivateRoute>
          }
        ></Route>
        <Route path='/utente/:id' element={<UserPage />} />

        <Route path='/contattaci' element={<ContactUsPage />} />

        <Route path='/chi-siamo' element={<AboutUsPage />} />
        {/* <Route path='/partner' element={<PartnerPage />} /> */}

        <Route
          path='/circolo/:id/membri'
          element={
            <PrivateRoute>
              <ClubMembersPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/circolo/:id/competizioni'
          element={
            <PrivateRoute>
              <ClubCompetitionsPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/circolo/:id/social'
          element={
            <PrivateRoute>
              <ClubSocialPage />
            </PrivateRoute>
          }
        ></Route>
        <Route path='/circolo/:id' element={<ClubPage />} />
        <Route path='/leggicircolo.php' element={<RedirectOldPages />} />

        <Route path='/shop/:id' element={<ShopProfilePage />} />

        <Route path='/competizione/:id' element={<CompetitionPage />} />
        <Route path='/torneo/:id' element={<TournamentPage />} />
        <Route
          path='/tabellone/:id/aggiunta-partita'
          element={
            <PrivateRoute>
              <DrawNewMatchPage />
            </PrivateRoute>
          }
        ></Route>
        <Route path='/tabellone/:id' element={<DrawPage />} />

        <Route
          path='/notifiche'
          element={
            <PrivateRoute>
              <NotificationsPage />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path='/diventa-member'
          element={
            <PrivateRoute>
              <PremiumizePage />
            </PrivateRoute>
          }
        >
          <Route
            path='/diventa-member/:code'
            element={
              <PrivateRoute>
                <PremiumizePage />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path='/club-membership' element={<PremiumPage />}></Route>

        <Route path='/post/:id' element={<PostPage />} />

        <Route path='/ultimo-post' element={<PostPage />} />

        <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route path='/condizioni-di-utilizzo' element={<TermsOfUsePage />} />
        <Route path='/faq' element={<FaqPage />} />

        <Route path='/ricerca-competizioni' element={<CompetitionAdvancedSearchPage />} />
        <Route path='/classifiche' element={<PlayersAdvancedSearchPage />} />

        <Route path='*' element={<NotFoundPage />} />
      </SentryRoutes>
    </Suspense>
  );
};

export default RoutesDefinition;
