import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';

export type PasswordResetParameters = {
  token: string;
  password: string;
};
export const reset = createAsyncThunk<{ success: boolean }, PasswordResetParameters>(
  'reset-password/reset',
  async ({ token, password }, { rejectWithValue }) => {
    const response = await callTk<{ success: boolean }>(
      'reset-password',
      { method: 'PUT', body: JSON.stringify({ token, password }) },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const passwordResetSlice = createSlice({
  name: 'passwordReset',
  initialState: {
    error: false,
    loading: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reset.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(reset.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(reset.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const passwordResetReducer = passwordResetSlice.reducer;
