import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { FollowUnfollowParameters } from 'store/followUnfollow/followUnfollowSlice';
import { setFollowed, setUnfollowed } from 'store/followUnfollow/followUnfollowSlice';
import type { ShopType } from 'types/user';

const FOLLOWABLE_TYPE = 'shop_profile';

export const fetchShopProfile = createAsyncThunk<ShopType | null, number | string | undefined>(
  'shop-profiles/fetchShopProfile',
  async (id, { rejectWithValue }) => {
    if (id) {
      const response = await callTk<ShopType>(
        `shop-profiles/${id}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return null;
  },
);

export const shopProfileSlice = createSlice({
  name: 'shopProfile',
  initialState: {
    error: false,
    loading: false,
    shop: null as ShopType | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShopProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchShopProfile.fulfilled, (state, action: PayloadAction<ShopType | null>) => {
      state.loading = false;
      state.shop = action.payload;
    });
    builder.addCase(fetchShopProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(setFollowed, (state, action: PayloadAction<FollowUnfollowParameters>) => {
      const { id, type } = action.payload;
      if (type === FOLLOWABLE_TYPE && state.shop && state.shop.id === id) {
        state.shop.followed = true;
      }
    });
    builder.addCase(setUnfollowed, (state, action: PayloadAction<FollowUnfollowParameters>) => {
      const { id, type } = action.payload;
      if (type === FOLLOWABLE_TYPE && state.shop && state.shop.id === id) {
        state.shop.followed = false;
      }
    });
  },
});

export const shopProfileReducer = shopProfileSlice.reducer;
