import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import { postCreated } from 'store/feed/feedSlice';
import type { NewPostCallType, NewPostParamsType, PostType } from 'types/post';

export const createPost = createAsyncThunk<NewPostCallType, NewPostParamsType & { callBack?: () => void }>(
  'posts/createPost',
  async ({ content, profile, sharedPostId, asAdmin, images, video, callBack }, { rejectWithValue, dispatch }) => {
    const response = await call<NewPostCallType>(
      'posts',
      {
        method: 'POST',
        body: JSON.stringify({
          content,
          masqueraded_id: profile.id,
          masqueraded_type: profile.type,
          shared_post_id: sharedPostId,
          as_tennistalker: asAdmin,
          images: images,
          video: video,
        }),
      },
      (response) => {
        dispatch(postCreated(response.post));
        dispatch(clearNewPost());
        setTimeout(() => {
          if (callBack) callBack();
        }, 2000);
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const newPostSlice = createSlice({
  name: 'newPost',
  initialState: {
    error: false,
    loading: false,
    sharedPost: null as PostType | null,
    content: '',
  },
  reducers: {
    sharePost: (state, action) => {
      state.sharedPost = action.payload;
    },
    setNewPost: (state, action) => {
      state.content = action.payload;
    },
    clearNewPost: (state) => {
      state.content = '';
      state.sharedPost = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPost.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(createPost.fulfilled, (state) => {
      state.loading = false;
      successToastMessage('Il tuo post è stato pubblicato.');
    });
    builder.addCase(createPost.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore nella creazione del post.');
    });
  },
});

export const { sharePost, setNewPost, clearNewPost } = newPostSlice.actions;
export const newPostReducer = newPostSlice.reducer;
