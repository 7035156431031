import { combineReducers } from 'redux';

import { accountConfirmationSlice } from './accountConfirmation/accountConfirmationSlice';
import { additionalInformationSlice } from './additionalInformation/additionalInformationSlice';
import { authenticationSlice } from 'store/authentication/authenticationSlice';
import { clubProfileSlice } from 'store/clubProfile/clubProfileSlice';
import { competitionSlice } from 'store/competition/competitionSlice';
import { competitionAdvancedSearchSlice } from 'store/competitionAdvancedSearch/competitionAdvancedSearchSlice';
import { completeProfileSlice } from 'store/completeProfile/completeProfileSlice';
import { contactSlice } from 'store/contacts/contactSlice';
import { conversationsSlice } from 'store/conversations/conversationsSlice';
import { drawSlice } from 'store/draw/drawSlice';
import { editProfileSlice } from 'store/editProfile/editProfileSlice';
import { endorsementsSlice } from 'store/endorsements/endorsementsSlice';
import { feedSlice } from 'store/feed/feedSlice';
import { followUnfollowSlice } from 'store/followUnfollow/followUnfollowSlice';
import { homeNumbersSlice } from 'store/homeNumbers/homeNumbersSlice';
import { inlineSearchSlice } from 'store/inlineSearch/inlineSearchSlice';
import { invitationSlice } from 'store/invitation/invitationSlice';
import { marketEditOfferSlice } from 'store/marketEditOffer/marketEditOfferSlice';
import { marketNewOfferSlice } from 'store/marketNewOffer/marketNewOfferSlice';
import { marketSlice } from 'store/market/marketSlice';
import { messagesSlice } from 'store/messages/messagesSlice';
import { modalsSlice } from 'store/modals/modalsSlice';
import { newMessageSlice } from 'store/newMessage/newMessageSlice';
import { newPostSlice } from 'store/newPost/newPostSlice';
import { notificationsSlice } from 'store/notifications/notificationsSlice';
import { offerSlice } from 'store/offer/offerSlice';
import { passwordResetSlice } from 'store/passwordReset/passwordResetSlice';
import { playerProfileSlice } from 'store/playerProfile/playerProfileSlice';
import { playersAdvancedSearchSlice } from 'store/playersAdvancedSearch/playersAdvancedSearchSlice';
import { premiumSlice } from 'store/premium/premiumSlice';
import { profileFollowersSlice } from 'store/profileFollowers/profileFollowersSlice';
import { profileSelectorSlice } from 'store/profileSelector/profileSelectorSlice';
import { reportSlice } from 'store/report/reportSlice';
import { requestPasswordResetSlice } from 'store/requestPasswordReset/requestPasswordResetSlice';
import { searchSlice } from 'store/search/searchSlice';
import { shopProfileSlice } from 'store/shopProfile/shopProfileSlice';
import { signUpSlice } from 'store/signUp/signUpSlice';
import { simulatorSlice } from 'store/simulator/simulatorSlice';
import { tournamentSlice } from 'store/tournament/tournamentSlice';
import { updateMatchResultSlice } from 'store/updateMatchResult/updateMatchResultSlice';
import { userBonusSlice } from 'store/userBonus/userBonusSlice';
import { userMatchSlice } from 'store/userMatch/userMatchSlice';
import { userProfileSlice } from './userProfile/userProfileSlice';
import { visitorsSlice } from 'store/visitors/visitorsSlice';
import { userPreviewSlice } from './userPreview/userPreviewSlice';
import { uiSlice } from './misc/uiSlice';

const rootReducer = combineReducers({
  accountConfirmation: accountConfirmationSlice.reducer,
  additionalInformation: additionalInformationSlice.reducer,
  authentication: authenticationSlice.reducer,
  clubProfile: clubProfileSlice.reducer,
  competition: competitionSlice.reducer,
  competitionsAdvancedSearch: competitionAdvancedSearchSlice.reducer,
  completeProfile: completeProfileSlice.reducer,
  contacts: contactSlice.reducer,
  conversations: conversationsSlice.reducer,
  draw: drawSlice.reducer,
  editProfile: editProfileSlice.reducer,
  endorsements: endorsementsSlice.reducer,
  feed: feedSlice.reducer,
  followUnfollow: followUnfollowSlice.reducer,
  homeNumbers: homeNumbersSlice.reducer,
  inlineSearch: inlineSearchSlice.reducer,
  invitation: invitationSlice.reducer,
  market: marketSlice.reducer,
  marketEditOffer: marketEditOfferSlice.reducer,
  marketNewOffer: marketNewOfferSlice.reducer,
  messages: messagesSlice.reducer,
  modals: modalsSlice.reducer,
  newMessage: newMessageSlice.reducer,
  newPost: newPostSlice.reducer,
  notifications: notificationsSlice.reducer,
  offer: offerSlice.reducer,
  passwordReset: passwordResetSlice.reducer,
  playerProfile: playerProfileSlice.reducer,
  playersAdvancedSearch: playersAdvancedSearchSlice.reducer,
  premium: premiumSlice.reducer,
  profileFollowers: profileFollowersSlice.reducer,
  profileSelector: profileSelectorSlice.reducer,
  report: reportSlice.reducer,
  requestPasswordReset: requestPasswordResetSlice.reducer,
  search: searchSlice.reducer,
  shopProfile: shopProfileSlice.reducer,
  signUp: signUpSlice.reducer,
  simulator: simulatorSlice.reducer,
  tournament: tournamentSlice.reducer,
  updateMatchResult: updateMatchResultSlice.reducer,
  userPreview: userPreviewSlice.reducer,
  userBonuses: userBonusSlice.reducer,
  userMatches: userMatchSlice.reducer,
  userProfile: userProfileSlice.reducer,
  visitors: visitorsSlice.reducer,
  ui: uiSlice.reducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
