import type { SavedUserType, UserDetailType } from 'types/user';

export const hasAdditionalInformation = (user: UserDetailType | SavedUserType) => {
  const { first_name, last_name, birth_date }: { first_name: string; last_name: string; birth_date: string | Date } =
    user;
  return [
    first_name && first_name !== '',
    last_name && last_name !== '',
    birth_date && (typeof birth_date === 'string' ? birth_date !== '' : true),
  ].every((valid) => valid === true);
};
