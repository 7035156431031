import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk, clearCacheMap } from 'api';
import { currentUser, setCurrentUser } from 'helpers/authentication';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import { fetchTeacherQualifications, fetchTrainerQualifications } from 'store/completeProfile/completeProfileSlice';
import type {
  CompleteProfileCallType,
  CompleteProfileParamsType,
  EditGeneralInfoType,
  EditProfileClubManagerType,
  PlayerProfileParamsType,
  ProfilesType,
  TeacherQualification,
  UpdateAccountInfoParamsType,
  UserDetailType,
  VerifyClubCallType,
} from 'types/user';

const postEditActions = (user: UserDetailType) => {
  const oldUser = currentUser();
  setCurrentUser({
    ...oldUser,
    fit_player_profile_id: user.fit_player_profile_id,
    shop_profile_id: user.shop_profile_id,
    profiles: user.profiles,
    available_profiles: user.available_profiles,
    player: user.player,
  });
  clearCacheMap();
  successToastMessage('Rimozione associazione effettuata con successo.');
  window.location.reload();
};

//TODO forse da rimuovere?
export const removePlayerProfileAssociations = createAsyncThunk<{ success: boolean }, string>(
  'edit-profile/remove-player-profile-associations/removePlayerProfileAssociations',
  async (fitCardNumber, { rejectWithValue }) => {
    const response = await callTk<{ success: boolean }>(
      'edit-profile/remove-player-profile-associations',
      { method: 'PUT', body: JSON.stringify({ fit_card_number: fitCardNumber }) },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const removePlayerProfile = createAsyncThunk<CompleteProfileCallType, void>(
  'edit-profile/remove-player-profile/removePlayerProfile',
  async (_, { rejectWithValue }) => {
    const response = await callTk<CompleteProfileCallType>(
      'edit-profile/remove-player-profile',
      { method: 'PUT' },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const deleteProfile = createAsyncThunk<CompleteProfileCallType, string>(
  'edit-profile/delete-profile/deleteProfile',
  async (profile, { rejectWithValue }) => {
    const response = await callTk<CompleteProfileCallType>(
      `edit-profile/delete-profile?profile=${profile}`,
      { method: 'PUT' },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const fetchProfiles = createAsyncThunk<ProfilesType, void>(
  'edit-profile/profiles/fetchProfiles',
  async (_, { rejectWithValue }) => {
    clearCacheMap();
    const response = await callTk<ProfilesType>('edit-profile/profiles', { method: 'GET' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const saveAppToken = createAsyncThunk<CompleteProfileCallType, { os: string; token: string }>(
  'edit-profile/app-token-info/saveAppToken',
  async (info, { rejectWithValue }) => {
    const response = await callTk<CompleteProfileCallType>(
      'edit-profile/app-token-info',
      { method: 'PUT', body: JSON.stringify({ subscription_token: info.token, os: info.os }) },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const updateGeneralInfo = createAsyncThunk<CompleteProfileCallType, EditGeneralInfoType>(
  'edit-profile/general-info/updateGeneralInfo',
  async (info, { rejectWithValue }) => {
    const response = await callTk<CompleteProfileCallType>(
      'edit-profile/general-info',
      {
        method: 'PUT',
        body: JSON.stringify({
          first_name: info.firstName,
          last_name: info.lastName,
          birth_date: info.birthDate,
          phone: info.phone,
          address_user_input: info.address,
          photo: info.photo,
          bio: info.bio,
          preferred_surface: info.preferred_surface,
          racket_brand: info.racket_brand,
          shoes_brand: info.shoes_brand,
          clothing_brand: info.clothing_brand,
          lefthanded: info.lefthanded,
          ig_id: info.ig_id,
          fb_id: info.fb_id,
          nickname: info.nickname,
        }),
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const updateAccountInfo = createAsyncThunk<CompleteProfileCallType, UpdateAccountInfoParamsType>(
  'edit-profile/account-info/updateAccountInfo',
  async (info, { rejectWithValue }) => {
    const response = await callTk<CompleteProfileCallType>(
      'edit-profile/account-info',
      {
        method: 'PUT',
        body: JSON.stringify({
          email: info.email,
          password: info.password,
          password_confirmation: info.passwordConfirmation,
          email_notifications: info.email_notifications,
          notifications_for_posts: info.notifications_for_posts,
          notifications_for_comments: info.notifications_for_comments,
          marketing_communication_check: info.marketing_communication_check,
        }),
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const updateProfilesInfo = createAsyncThunk<CompleteProfileCallType, CompleteProfileParamsType>(
  'edit-profile/profiles-info/updateProfilesInfo',
  async (info, { rejectWithValue }) => {
    const response = await callTk<CompleteProfileCallType>(
      'edit-profile/profiles-info',
      {
        method: 'PUT',
        body: JSON.stringify({
          fit_player_profile: {
            fit_card_number: info.fitPlayerProfile.fitCardNumber,
            selected_player: info.fitPlayerProfile.selectedPlayer,
          },
          teacher_profile: {
            qualification: info.teacherProfile.qualification,
            professional_info: info.teacherProfile.professionalInfo,
          },
          trainer_profile: {
            qualification: info.trainerProfile.qualification,
            professional_info: info.trainerProfile.professionalInfo,
          },
          nutritionist_profile: {
            qualifications: info.nutritionistProfile.qualifications,
            professional_info: info.nutritionistProfile.professionalInfo,
          },
          mental_coach_profile: {
            qualifications: info.mentalCoachProfile.qualifications,
            professional_info: info.mentalCoachProfile.professionalInfo,
          },
          club_manager_profile: {
            fit_club_id: info.clubManagerProfile.fitClubId,
            photo: info.clubManagerProfile.photo,
          },
          shop_profile: {
            name: info.shopProfile.name,
            address: info.shopProfile.address,
            website: info.shopProfile.website,
            info: info.shopProfile.info,
          },
        }),
      },
      (error) => rejectWithValue(error),
    );

    if (
      info.clubManagerProfile.fitClubId &&
      info.clubManagerProfile.touched &&
      [undefined, ''].includes(info.clubManagerProfile.fitClubIdVerificationError)
    ) {
      successToastMessage(
        'Informazioni sui profili aggiornate correttamente. La tua richiesta di gestire il circolo specificato verrà verificata da un admin',
      );
    } else {
      successToastMessage('Informazioni sui profili aggiornate correttamente.');
    }

    return response;
  },
);

export const updatePlayerProfilesInfo = createAsyncThunk<CompleteProfileCallType, PlayerProfileParamsType>(
  'edit-profile/profiles-info/updatePlayerProfilesInfo',
  async (info, { rejectWithValue }) => {
    const response = await callTk<CompleteProfileCallType>(
      'edit-profile/profiles-info',
      {
        method: 'PUT',
        body: JSON.stringify({
          fit_player_profile: {
            fit_card_number: info.fitPlayerProfile.fitCardNumber,
            selected_player: info.fitPlayerProfile.selectedPlayer,
          },
        }),
      },
      (error) => rejectWithValue(error),
    );

    return response;
  },
);

export const verifyClub = createAsyncThunk<VerifyClubCallType, EditProfileClubManagerType>(
  'users/check-fit-club-id/verifyClub',
  async (clubManagerProfile, { rejectWithValue }) => {
    const response = await callTk<VerifyClubCallType>(
      'users/check-fit-club-id',
      {
        method: 'POST',
        body: JSON.stringify({
          fit_club_id: clubManagerProfile.fitClubId,
        }),
      },
      (error) => rejectWithValue(error),
    );

    return response;
  },
);

export const editProfileSlice = createSlice({
  name: 'editProfile',
  initialState: {
    loading: false,
    error: false,

    teacherQualifications: [] as TeacherQualification[],
    trainerQualifications: [] as TeacherQualification[],
    profiles: null as ProfilesType | null,
    fitClubIdVerificationError: undefined as string | undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTeacherQualifications.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTeacherQualifications.fulfilled, (state, action: PayloadAction<TeacherQualification[]>) => {
      state.loading = false;
      state.teacherQualifications = action.payload;
    });
    builder.addCase(fetchTeacherQualifications.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchTrainerQualifications.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTrainerQualifications.fulfilled, (state, action: PayloadAction<TeacherQualification[]>) => {
      state.loading = false;
      state.trainerQualifications = action.payload;
    });
    builder.addCase(fetchTrainerQualifications.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(removePlayerProfileAssociations.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(removePlayerProfileAssociations.fulfilled, (state) => {
      state.loading = false;
      successToastMessage('Rimozione associazione effettuata con successo.');
    });
    builder.addCase(removePlayerProfileAssociations.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore.');
    });
    builder.addCase(removePlayerProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(removePlayerProfile.fulfilled, (state, action: PayloadAction<CompleteProfileCallType>) => {
      state.loading = false;
      //TODO check perchè non aggiorna il modifica profilo
      postEditActions(action.payload.user);
    });
    builder.addCase(removePlayerProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore.');
    });
    builder.addCase(deleteProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteProfile.fulfilled, (state, action: PayloadAction<CompleteProfileCallType>) => {
      state.loading = false;
      postEditActions(action.payload.user);
    });
    builder.addCase(deleteProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore.');
    });
    builder.addCase(saveAppToken.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(saveAppToken.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(saveAppToken.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchProfiles.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchProfiles.fulfilled, (state, action: PayloadAction<ProfilesType>) => {
      state.loading = false;
      state.profiles = action.payload;
    });
    builder.addCase(fetchProfiles.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore nel caricamento dei profili.');
    });
    builder.addCase(updateGeneralInfo.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(updateGeneralInfo.fulfilled, (state, action: PayloadAction<CompleteProfileCallType>) => {
      state.loading = false;
      const user = currentUser();
      setCurrentUser({
        ...user,
        first_name: action.payload.user.first_name,
        last_name: action.payload.user.last_name,
        name: `${action.payload.user.first_name} ${action.payload.user.last_name}`,
        birth_date: action.payload.user.birth_date,
        phone: action.payload.user.phone,
        address: action.payload.user.address,
        bio: action.payload.user.bio,
        preferred_surface: action.payload.user.preferred_surface,
        racket_brand: action.payload.user.racket_brand,
        shoes_brand: action.payload.user.shoes_brand,
        clothing_brand: action.payload.user.clothing_brand,
        lefthanded: action.payload.user.lefthanded,
        ig_id: action.payload.user.ig_id,
        fb_id: action.payload.user.fb_id,
        nickname: action.payload.user.nickname,
      });
      if (action.payload.user.thumbnail !== user.photo_url || (action.payload.user.thumbnail && user.photo_url)) {
        setCurrentUser({
          ...currentUser(),
          available_profiles: action.payload.user.available_profiles,
          photo_url: action.payload.user.thumbnail,
        });
      }
      successToastMessage('Informazioni generali aggiornate correttamente.');
    });
    builder.addCase(updateGeneralInfo.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un errore nell'aggiornamento delle informazioni.");
    });
    builder.addCase(updateAccountInfo.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(updateAccountInfo.fulfilled, (state, action: PayloadAction<CompleteProfileCallType>) => {
      state.loading = false;
      const user = currentUser();
      setCurrentUser({
        ...user,
        email: action.payload.user.email,
        email_notifications: action.payload.user.email_notifications,
        notifications_for_posts: action.payload.user.notifications_for_posts,
        notifications_for_comments: action.payload.user.notifications_for_comments,
        marketing_communication_check: action.payload.user.marketing_communication_check,
      });
      successToastMessage('Account aggiornato correttamente.');
    });
    builder.addCase(updateAccountInfo.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un errore nell'aggiornamento dell'account.");
    });
    builder.addCase(updateProfilesInfo.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(updateProfilesInfo.fulfilled, (state, action: PayloadAction<CompleteProfileCallType>) => {
      state.loading = false;
      postEditActions(action.payload.user);
    });
    builder.addCase(updateProfilesInfo.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un errore nell'aggiornamento delle informazioni sui profili.");
    });
    builder.addCase(updatePlayerProfilesInfo.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(updatePlayerProfilesInfo.fulfilled, (state, action: PayloadAction<CompleteProfileCallType>) => {
      state.loading = false;
      postEditActions(action.payload.user);
    });
    builder.addCase(updatePlayerProfilesInfo.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un errore nell'aggiornamento delle informazioni sui profili.");
    });
    builder.addCase(verifyClub.pending, (state) => {
      state.error = false;
    });
    builder.addCase(verifyClub.fulfilled, (state, action: PayloadAction<VerifyClubCallType>) => {
      if (action.payload.error) {
        state.fitClubIdVerificationError = action.payload.error;
      } else {
        state.fitClubIdVerificationError = '';
      }
    });
    builder.addCase(verifyClub.rejected, (state) => {
      state.error = true;
      errorToastMessage("Si è verificato un errore durante il controllo dell'identificativo FITP del circolo.");
    });
  },
});

export const editProfileReducer = editProfileSlice.reducer;
