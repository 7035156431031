import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { encodeToUrlQueryParameters } from 'helpers/utils';
import type { Rank, SimulatorCallType, SimulatorParametersType, SimulatorStateType } from 'types/player';

export const compute = createAsyncThunk<SimulatorCallType, SimulatorParametersType>(
  'fit-player-profiles/simulator/compute',
  async ({ playerId, year, simulatedRank, isSuperSimulation }, { rejectWithValue }) => {
    const query = encodeToUrlQueryParameters({
      year,
      simulated_rank: simulatedRank,
      is_super_simulation: isSuperSimulation,
    });
    const response = await callTk<SimulatorCallType>(
      `fit-player-profiles/${playerId}/simulator?${query}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const simulatorSlice = createSlice({
  name: 'simulator',
  initialState: {
    error: false,
    loading: false,
    availableYears: [] as number[],
    availableYearsReversed: [] as number[],
    year: undefined as number | undefined,
    simulatedRank: undefined as Rank | undefined,
    simulator: undefined as SimulatorStateType | undefined,
    competitionsNumber: undefined as number | undefined,
  },
  reducers: {
    clear: (state) => {
      state.error = false;
      state.loading = false;
      state.year = undefined;
      state.simulatedRank = undefined;
      state.simulator = undefined;
      state.competitionsNumber = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(compute.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(compute.fulfilled, (state, action: PayloadAction<SimulatorCallType>) => {
      state.loading = false;
      state.availableYears = action.payload.available_years;
      state.availableYearsReversed = action.payload.available_years.reverse();
      state.year = action.payload.current_year;
      state.competitionsNumber = action.payload.competitions_number;
      state.simulatedRank = action.payload.simulated_rank;
      state.simulator = {
        year: action.payload.current_year,
        rankInfo: action.payload.rank_info,
        simulatableRanks: action.payload.simulatable_ranks,
        competitionsNumber: action.payload.competitions_number,
        isSuperSimulation: action.payload.is_super_simulation,
        matches: action.payload.matches,
        age: action.payload.age,
      };
    });
    builder.addCase(compute.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clear } = simulatorSlice.actions;
export const simulatorReducer = simulatorSlice.reducer;
