import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { currentUser, setCurrentUser } from 'helpers/authentication';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import type { PremiumizeResponseType, PremiumParameters } from 'types/user';

export const premiumizeAccount = createAsyncThunk<PremiumizeResponseType, PremiumParameters>(
  'users/premiumize/invite',
  async ({ userId, codeValue }, { rejectWithValue }) => {
    const response = await callTk<PremiumizeResponseType>(
      `users/${userId}/premiumize`,
      {
        method: 'POST',
        body: JSON.stringify({
          code: codeValue,
        }),
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const premiumSlice = createSlice({
  name: 'premium',
  initialState: {
    error: false,
    loading: false,
    premiumized: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(premiumizeAccount.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(premiumizeAccount.fulfilled, (state, action: PayloadAction<PremiumizeResponseType>) => {
      state.loading = false;
      state.premiumized = true;
      const expirationDate = new Date(action.payload.premium_expiration);
      setCurrentUser({
        ...currentUser(),
        premium: true,
        premium_expiration: expirationDate,
      });
      successToastMessage(
        `L'attivazione è andata a buon fine. L'account premium sarà attivo fino al ${expirationDate.toLocaleDateString(
          'it-IT',
        )}`,
      );
    });
    builder.addCase(premiumizeAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      const error = action.payload as string;
      errorToastMessage(error ?? 'Si è verificato un errore.');
    });
  },
});

export const premiumReducer = premiumSlice.reducer;
