import type { URLOpenListenerEvent } from '@capacitor/app';
import { App } from '@capacitor/app';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAppUrlListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let urlOpenListener: PluginListenerHandle | null = null;
    const setupListeners = async () => {
      urlOpenListener = await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://tennistalker.it/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.it').pop();
        if (slug) {
          navigate(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    };
    if (Capacitor.isNativePlatform()) {
      setupListeners();
    }
    return () => {
      urlOpenListener?.remove();
    };
    // eslint-disable-next-line
  }, []);
};

export default useAppUrlListener;
