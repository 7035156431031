import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Analytics from 'ui/design-base/Analytics';

export const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    Analytics.init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    Analytics.sendPageview(currentPath);
  }, [location]);
};
