import { useScript } from 'helpers/utils';
import { useEffect, useState } from 'react';
import LoadAdvAdKaora from './LoadAdvAdKaora';

const LoadCookieConsentAndAds = () => {
  const [loadAds, setLoadAds] = useState(false);

  const [loaded] = useScript(
    'https://app.termly.io/resource-blocker/a0c8b22d-95ff-4b10-a8c8-91e1403624f2?autoBlock=on',
  );
  useEffect(() => {
    if (loaded) {
      setLoadAds(true);
    }
  }, [loaded]);
  return <>{loadAds && <LoadAdvAdKaora />}</>;
};

export default LoadCookieConsentAndAds;
