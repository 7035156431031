import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { CategoryListType, MyOfferParamsType, OfferParamsType, OffersCallType, OfferType } from 'types/market';

export const fetchCategories = createAsyncThunk<CategoryListType[], void>(
  'offer-categories/fetchCategories',
  async (_, { rejectWithValue }) => {
    const response = await callTk<CategoryListType[]>(
      'offer-categories',
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchOffers = createAsyncThunk<OffersCallType, OfferParamsType>(
  'offers/search/fetchOffers',
  async ({ query, category, secondHand, page }, { rejectWithValue }) => {
    const response = await callTk<OffersCallType>(
      'offers/search',
      {
        method: 'POST',
        body: JSON.stringify({
          q: query,
          category_id: category,
          second_hand: secondHand,
          page,
        }),
      },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchMyOffers = createAsyncThunk<OffersCallType, MyOfferParamsType>(
  'users/market/fetchMyOffers',
  async ({ profile, category, page }, { rejectWithValue }) => {
    const response = await callTk<OffersCallType>(
      'users/market',
      {
        method: 'POST',
        body: JSON.stringify({
          ...profile,
          category_id: category,
          page,
        }),
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const marketSlice = createSlice({
  name: 'market',
  initialState: {
    loading: false,
    error: false,

    query: '',
    category: null as number | null,
    secondHand: true,
    page: 1,
    totalPages: 1,

    categories: [] as CategoryListType[],
    offers: [] as OfferType[],
  },
  reducers: {
    clearMarketData: (state) => {
      state.query = '';
      state.category = null;
      state.secondHand = true;
      state.page = 1;
      state.totalPages = 1;
      state.offers = [];
    },
    setMarketQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setMarketQuality: (state, action: PayloadAction<boolean>) => {
      state.secondHand = action.payload;
      state.page = 1;
    },
    setMarketCategory: (state, action: PayloadAction<number>) => {
      if (state.category !== action.payload) {
        const selectedCategory = action.payload;
        const parent = state.categories.find((category) =>
          category.children.map((subCategory) => subCategory.id).includes(selectedCategory),
        );
        state.category = selectedCategory;
        state.page = 1;
        state.categories = state.categories.map((category) => ({
          ...category,
          selected: category.id === parent?.id,
        }));
      } else {
        state.category = null;
        state.page = 1;
        state.categories = state.categories.map((category) => ({
          ...category,
          selected: false,
        }));
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action: PayloadAction<CategoryListType[]>) => {
      state.loading = false;
      state.categories = action.payload;
    });
    builder.addCase(fetchCategories.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchOffers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchOffers.fulfilled, (state, action: PayloadAction<OffersCallType>) => {
      state.loading = false;
      state.offers = action.payload.offers;
      state.totalPages = action.payload.total_pages;
      state.page = action.payload.page;
    });
    builder.addCase(fetchOffers.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchMyOffers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchMyOffers.fulfilled, (state, action: PayloadAction<OffersCallType>) => {
      state.loading = false;
      state.offers = action.payload.offers;
      state.totalPages = action.payload.total_pages;
      state.page = action.payload.page;
    });
    builder.addCase(fetchMyOffers.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clearMarketData, setMarketQuery, setMarketQuality, setMarketCategory } = marketSlice.actions;
export const marketReducer = marketSlice.reducer;
