import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { FollowersCallType } from 'types';
import type { FollowerUserType } from 'types/user';

export const fetchFollowersByProfile = createAsyncThunk<FollowersCallType, string | number | undefined>(
  'fit-player-profiles/followers_list/fetchFollowersByProfile',
  async (id, { rejectWithValue }) => {
    if (id) {
      const response = await callTk<FollowersCallType>(
        `fit-player-profiles/followers_list/${id}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return { followers: [], following: [] };
  },
);

export const fetchFollowersByUser = createAsyncThunk<FollowersCallType, string | number | undefined>(
  'users/followers_list/fetchFollowersByUser',
  async (id, { rejectWithValue }) => {
    if (id) {
      const response = await callTk<FollowersCallType>(
        `users/followers_list/${id}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return { followers: [], following: [] };
  },
);

export const profileFollowersSlice = createSlice({
  name: 'profileFollowers',
  initialState: {
    followers: [] as FollowerUserType[],
    following: [] as FollowerUserType[],
    error: false,
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFollowersByProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFollowersByProfile.fulfilled, (state, action: PayloadAction<FollowersCallType>) => {
      state.loading = false;
      state.followers = action.payload.followers;
      state.following = action.payload.following;
    });
    builder.addCase(fetchFollowersByProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFollowersByUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFollowersByUser.fulfilled, (state, action: PayloadAction<FollowersCallType>) => {
      state.loading = false;
      state.followers = action.payload.followers;
      state.following = action.payload.following;
    });
    builder.addCase(fetchFollowersByUser.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const profileFollowersReducer = profileFollowersSlice.reducer;
