import type { Store, ThunkDispatch } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import type { RootState } from './index';
import createRootReducer from './index';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

export const history = createBrowserHistory();

const store: Store = configureStore({
  reducer: createRootReducer,
});

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
type AppAction = ReturnType<typeof store.dispatch>;
export type AppDispatch = ThunkDispatch<RootState, undefined, AppAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
