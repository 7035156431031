import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import { messageSent } from 'store/messages/messagesSlice';
import { hideModal } from 'store/modals/modalsSlice';
import type { MessageCallType, NewMessageParamsType } from 'types/user';

export const sendMessage = createAsyncThunk<MessageCallType, NewMessageParamsType>(
  'messages/sendMessage',
  async ({ sender, recipient, message }, { rejectWithValue, dispatch }) => {
    const response = await call<MessageCallType>(
      'messages',
      {
        method: 'POST',
        body: JSON.stringify({
          author_id: sender.id,
          author_type: sender.type,
          recipient_id: recipient.id,
          recipient_type: recipient.type,
          content: message,
        }),
      },
      (response) => {
        dispatch(messageSent(response.message));
        dispatch(hideModal('message-form'));
        return response;
      },
      (error) => rejectWithValue(error),
    );

    return response;
  },
);

export const newMessageSlice = createSlice({
  name: 'newMessage',
  initialState: {
    error: false,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendMessage.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(sendMessage.fulfilled, (state) => {
      state.loading = false;
      successToastMessage('Messaggio inviato con successo.');
    });
    builder.addCase(sendMessage.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Errore nell'invio del messaggio.");
    });
  },
});

export const newMessageReducer = newMessageSlice.reducer;
