import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import { hideModal } from 'store/modals/modalsSlice';
import type {
  DeleteMatchParamsType,
  FetchMatchParamsType,
  MatchCallType,
  MatchDrawType,
  SaveMatchParamsType,
} from 'types/match';

export const deleteMatch = createAsyncThunk<MatchCallType, DeleteMatchParamsType>(
  'matches/deleteMatch',
  async ({ id, onCallback }, { rejectWithValue }) => {
    const response = await callTk<MatchCallType>(`matches/${id}`, { method: 'DELETE' }, (error) =>
      rejectWithValue(error),
    );
    onCallback && onCallback();
    return response;
  },
);

export const fetchMatchResult = createAsyncThunk<MatchDrawType, FetchMatchParamsType>(
  'matches/fetchMatchResult',
  async ({ id, type, onCallback }, { rejectWithValue }) => {
    let response;
    if (type === 'match') {
      response = await callTk<MatchDrawType>(`matches/${id}`, { method: 'GET' }, (error) => rejectWithValue(error));
    } else {
      response = await callTk<MatchDrawType>(`user-matches/${id}`, { method: 'GET' }, (error) =>
        rejectWithValue(error),
      );
    }
    onCallback && onCallback();
    return response;
  },
);

export const saveResult = createAsyncThunk<MatchCallType, SaveMatchParamsType>(
  'matches/result/saveResult',
  async ({ id, data, onCallback }, { rejectWithValue, dispatch }) => {
    let response;
    if (data.type === 'match') {
      response = await callTk<MatchCallType>(
        `matches/${id}/result`,
        {
          method: 'PUT',
          body: JSON.stringify({ result: data.result, is_played: data.is_played }),
        },
        (error) => rejectWithValue(error),
      );
    } else {
      response = await callTk<MatchCallType>(
        `user-matches/${id}/result`,
        { method: 'PUT', body: JSON.stringify({ result: data.result }) },
        (error) => rejectWithValue(error),
      );
    }
    dispatch(hideModal('update-match-result-modal'));
    onCallback && onCallback();
    return response;
  },
);

export const updateMatchResultSlice = createSlice({
  name: 'updateMatchResult',
  initialState: {
    error: false,
    loading: false,
    id: 0,
    type: '',
    result: '',
    is_played: true,
    player_id: 0,
  },
  reducers: {
    updateResult: (state, action: PayloadAction<string>) => {
      state.result = action.payload;
    },
    updateIsPlayed: (state, action: PayloadAction<boolean>) => {
      state.is_played = action.payload;
    },
    setPlayerId: (state, action: PayloadAction<number>) => {
      state.player_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteMatch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteMatch.fulfilled, (state) => {
      state.loading = false;
      successToastMessage('Partita eliminata con successo!');
    });
    builder.addCase(deleteMatch.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore durante la cancellazione della partita.');
    });
    builder.addCase(fetchMatchResult.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchMatchResult.fulfilled, (state, action: PayloadAction<MatchDrawType>) => {
      state.loading = false;
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.result = action.payload.result;
      state.is_played = action.payload.is_played;
    });
    builder.addCase(fetchMatchResult.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(saveResult.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(saveResult.fulfilled, (state) => {
      state.loading = false;
      successToastMessage('Risultato salvato. Grazie per il tuo contributo!');
    });
    builder.addCase(saveResult.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un problema nell'aggiornamento del risultato");
    });
  },
});

export const { updateResult, updateIsPlayed, setPlayerId } = updateMatchResultSlice.actions;
export const offerReducer = updateMatchResultSlice.reducer;
