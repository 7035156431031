import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export const profileSelectorSlice = createSlice({
  name: 'profileSelector',
  initialState: {
    currentProfile: 0,
  },
  reducers: {
    selectProfile: (state, action: PayloadAction<number>) => {
      state.currentProfile = action.payload;
    },
  },
});

export const { selectProfile } = profileSelectorSlice.actions;
export const searchReducer = profileSelectorSlice.reducer;
