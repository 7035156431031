import { load } from '@fingerprintjs/fingerprintjs';
import type { ProfileType, SavedUserType } from 'types/user';
import { getDateMonthsDistance, getDateShort } from './formatTime';

const COOKIE_KEY = `${import.meta.env.VITE_ENV || 'development'}_tennistalker_current_user`;

export const logoutFrontend = () => {
  unsetCurrentUser();
  window.location.href = '/accedi';
};

export const openBecomePremiumPage = () => {
  window.location.href = '/club-membership';
};

export const setCurrentUser = (user: SavedUserType) => localStorage.setItem(COOKIE_KEY, JSON.stringify(user));

export const unsetCurrentUser = () => localStorage.removeItem(COOKIE_KEY);

export const currentUser: () => SavedUserType = () => {
  const user = getCurrentUser();
  if (user) {
    if (isExpired(user.token_expiration)) {
      unsetCurrentUser();
      return null;
    }
    return user;
  }
  return user;
};

export const isCurrentUserAdmin = () => isLoggedIn() && currentUser().role === 'admin';

export const isCurrentUserPremium = () => isLoggedIn() && currentUser().premium;

export const isCurrentUserPremiumMember: () => boolean = () =>
  isCurrentUserPremium() && getDateMonthsDistance(new Date(currentUser().premium_expiration), new Date()) < 2;

export const isCurrentUserPremiumClubMember: () => boolean = () =>
  isCurrentUserPremium() && getDateMonthsDistance(new Date(currentUser().premium_expiration), new Date()) >= 2;

export const isUserPremiumMember = (
  premium: boolean | undefined | null = false,
  premium_expiration: Date | null = new Date(),
) => (premium && premium_expiration ? getDateMonthsDistance(new Date(premium_expiration), new Date()) < 2 : false);

//TODO tenere e usare per prova premium , cambiare magari nome
export const isUserPremiumClubMember = (
  premium: boolean | undefined | null = false,
  premium_expiration: Date | null = new Date(),
) => (premium && premium_expiration ? getDateMonthsDistance(new Date(premium_expiration), new Date()) >= 2 : false);

const getCurrentUser = () => JSON.parse(localStorage.getItem(COOKIE_KEY) as string);

export const isLoggedIn = () => currentUser() && !isExpired(currentUser().token_expiration as string);

type CacheType = Record<string, boolean>;

const isExpired = (tokenExpiration: string): boolean => {
  // Initialize the cache if it doesn't exist
  if (!isExpired.cache) {
    isExpired.cache = {};
  }

  // Check if the value for this tokenExpiration is already cached
  if (isExpired.cache[tokenExpiration] === undefined) {
    isExpired.cache[tokenExpiration] = new Date(tokenExpiration) < new Date();
  }

  // Return the cached value
  return isExpired.cache[tokenExpiration];
};

// Attach a `cache` property to the function and type it
isExpired.cache = {} as CacheType;

export const getAPIToken = () => (currentUser() ? currentUser().token : '');

export const isCurrentUser = (userId?: number) => (currentUser() ? currentUser().id === userId : false);

export const getVisit = () => {
  if (window.visit) return window.visit;
  window.visit = `${Date.now().toString()}-${import.meta.env.VITE_VERSION}`;
  return window.visit;
};

export const userIsFitPlayerProfile = () =>
  currentUser()?.available_profiles?.some((profile: ProfileType) => profile.type === 'fit_player_profile');

export const isCrawler = () =>
  /HeadlessChrome/.test(window.navigator.userAgent) ||
  /bot|googlebot|crawler|spider|robot|Chrome-Lighthouse|crawling/i.test(window.navigator.userAgent);

export const isProduction = () => import.meta.env.VITE_ENV === 'production';

export const getFingerPrint = () => {
  if (window.fingerprint) return window.fingerprint;
  if (isCrawler()) {
    window.fingerprint = 'crawlerbot';
    return window.fingerprint;
  }
  (async () => {
    try {
      const fp = await load();
      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      const result = await fp.get();
      // This is the visitor identifier:
      const visitorId = result.visitorId;
      window.fingerprint = visitorId;
    } catch (error) {
      console.error(error);
      window.fingerprint = 'a55f2d5c-406b-428c-acd3-73804ed74cf5';
      return window.fingerprint;
    }
  })();
};

/**
 * Wait for a property to be set on window
 * @param {string} [name] the window property name set by the script (optonal)
 */
export const waitForProp = async (name: string) => {
  while (!window.hasOwnProperty(name))
    // define the condition as you like
    await new Promise((resolve) => setTimeout(resolve, 200));
};

export const membershipPopupKey = getDateShort(new Date().getTime() / 1000);

export const showMembershipPopup = () => {
  if (isCurrentUserPremium()) return false;
  if (!sessionStorage.getItem(membershipPopupKey)) {
    sessionStorage.setItem(membershipPopupKey, 'show');
    return true;
  } else if (sessionStorage.getItem(membershipPopupKey) === 'show') {
    return true;
  }
  incrementPopupValue();
  return false;
};

const intervalBetweenPopups = '3';
export const incrementPopupValue = () => {
  if (sessionStorage.getItem(membershipPopupKey) === 'show') {
    sessionStorage.setItem(membershipPopupKey, '1');
  } else if (sessionStorage.getItem(membershipPopupKey) === intervalBetweenPopups) {
    sessionStorage.setItem(membershipPopupKey, 'show');
  } else {
    const value = parseInt(sessionStorage.getItem(membershipPopupKey) ?? intervalBetweenPopups);
    sessionStorage.setItem(membershipPopupKey, (value + 1).toString());
  }
};
