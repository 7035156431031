import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { currentUser, isLoggedIn, setCurrentUser } from 'helpers/authentication';
import { successToastMessage } from 'helpers/toaster';
import type {
  CompleteProfileCallType,
  CompleteProfileParamsType,
  TeacherQualification,
  UserDetailType,
} from 'types/user';

export const fetchTeacherQualifications = createAsyncThunk<TeacherQualification[], void>(
  'users/teacher_qualifications/fetchTeacherQualifications',
  async (_, { rejectWithValue }) => {
    const response = await callTk<TeacherQualification[]>(
      'users/teacher_qualifications',
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchTrainerQualifications = createAsyncThunk<TeacherQualification[], void>(
  'users/trainer_qualifications/fetchTrainerQualifications',
  async (_, { rejectWithValue }) => {
    const response = await callTk<TeacherQualification[]>(
      'users/trainer_qualifications',
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchFitPlayerId = createAsyncThunk<{ fit_player_id: string }, string>(
  'users/fit-player-id/fetchFitPlayerId',
  async (token, { rejectWithValue }) => {
    const response = await callTk<{ fit_player_id: string }>(
      `users/${token}/fit-player-id`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const completeProfile = createAsyncThunk<CompleteProfileCallType, CompleteProfileParamsType>(
  'users/complete-profile/completeProfile',
  async (info, { rejectWithValue }) => {
    const response = await callTk<CompleteProfileCallType>(
      'users/complete-profile',
      {
        method: 'POST',
        body: JSON.stringify({
          token: info.token,
          fit_player_profile: {
            fit_card_number: info.fitPlayerProfile.fitCardNumber,
            selected_player: info.fitPlayerProfile.selectedPlayer,
          },
          teacher_profile: {
            qualification: info.teacherProfile.qualification,
            professional_info: info.teacherProfile.professionalInfo,
          },
          trainer_profile: {
            qualification: info.trainerProfile.qualification,
            professional_info: info.trainerProfile.professionalInfo,
          },
          nutritionist_profile: {
            qualifications: info.nutritionistProfile.qualifications,
            professional_info: info.nutritionistProfile.professionalInfo,
          },
          mental_coach_profile: {
            qualifications: info.mentalCoachProfile.qualifications,
            professional_info: info.mentalCoachProfile.professionalInfo,
          },
          club_manager_profile: {
            fit_club_id: info.clubManagerProfile.fitClubId,
          },
          shop_profile: {
            name: info.shopProfile.name,
            address: info.shopProfile.address,
            website: info.shopProfile.website,
            info: info.shopProfile.info,
          },
        }),
      },
      (error) => rejectWithValue(error),
    );
    if (
      info.clubManagerProfile.fitClubId &&
      info.clubManagerProfile.touched &&
      [undefined, ''].includes(info.clubManagerProfile.fitClubIdVerificationError)
    ) {
      successToastMessage('La tua richiesta di gestire il circolo specificato verrà verificata da un admin');
    }
    return response;
  },
);

export const completeProfileSlice = createSlice({
  name: 'completeProfile',
  initialState: {
    loading: false,
    error: false,
    fitCardNumber: '', //TODO remove
    teacherQualifications: [] as TeacherQualification[],
    trainerQualifications: [] as TeacherQualification[],
    fitPlayerId: '',
    user: null as UserDetailType | null,
  },
  reducers: {
    clearCompleteProfileData: (state) => {
      state.loading = false;
      state.error = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeacherQualifications.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTeacherQualifications.fulfilled, (state, action: PayloadAction<TeacherQualification[]>) => {
      state.loading = false;
      state.teacherQualifications = action.payload;
    });
    builder.addCase(fetchTeacherQualifications.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchTrainerQualifications.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTrainerQualifications.fulfilled, (state, action: PayloadAction<TeacherQualification[]>) => {
      state.loading = false;
      state.trainerQualifications = action.payload;
    });
    builder.addCase(fetchTrainerQualifications.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFitPlayerId.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFitPlayerId.fulfilled, (state, action: PayloadAction<{ fit_player_id: string }>) => {
      state.loading = false;
      state.fitPlayerId = action.payload.fit_player_id;
    });
    builder.addCase(fetchFitPlayerId.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(completeProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(completeProfile.fulfilled, (state, action: PayloadAction<CompleteProfileCallType>) => {
      state.loading = false;
      state.user = action.payload.user;
      if (isLoggedIn()) {
        const updatedUser = Object.assign({}, currentUser(), action.payload.user);
        setCurrentUser(updatedUser);
      }
      window.location.href = `/registrati/fine/${action.payload.user.confirmation_token}`;
    });
    builder.addCase(completeProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clearCompleteProfileData } = completeProfileSlice.actions;
export const completeProfileReducer = completeProfileSlice.reducer;
