import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type {
  Category,
  PlayerAdvancedSearchResultType,
  PlayerFiltersCallType,
  PlayersAdvancedSearchCallType,
  PlayersFiltersType,
  Rank,
} from 'types/player';

export const fetchFilters = createAsyncThunk<PlayerFiltersCallType, void>(
  'fit-player-profiles/advanced-search/filters/fetchFilters',
  async (_, { rejectWithValue }) => {
    const response = await callTk<PlayerFiltersCallType>(
      'fit-player-profiles/advanced-search/filters',
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetch = createAsyncThunk<PlayersAdvancedSearchCallType, PlayersFiltersType>(
  'fit-player-profiles/advanced-search/fetch',
  async (filters, { rejectWithValue }) => {
    const response = await callTk<PlayersAdvancedSearchCallType>(
      'fit-player-profiles/advanced-search',
      {
        method: 'POST',
        body: JSON.stringify({
          ...filters,
          age_categories: filters.selectedCategories,
          ranks: filters.selectedRanks,
        }),
      },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const playersAdvancedSearchSlice = createSlice({
  name: 'playersAdvancedSearch',
  initialState: {
    loading: false,
    error: false,
    data: {
      page: 1,
      total_pages: 0,
      results: [] as PlayerAdvancedSearchResultType[],
      results_numbers: 0,
    },
    categories: [] as Category[],
    ranks: [] as Rank[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<PlayersAdvancedSearchCallType>) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetch.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFilters.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFilters.fulfilled, (state, action: PayloadAction<PlayerFiltersCallType>) => {
      state.loading = false;
      state.categories = action.payload.age_categories;
      state.ranks = action.payload.ranks;
    });
    builder.addCase(fetchFilters.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const playersAdvancedSearchReducer = playersAdvancedSearchSlice.reducer;
