import { useEffect } from 'react';
import { App } from '@capacitor/app';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { Preferences } from '@capacitor/preferences';
import { AppReview } from '@capawesome/capacitor-app-review';
import { useAppDispatch } from 'store/store';
import { clearCacheMap } from 'api';

const LOCALSTORAGE_KEY = `${import.meta.env.VITE_ENV || 'development'}_tennistalker_app_state`;

const useAppLifecycle = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let resumeListener: PluginListenerHandle | null = null;

    const setupListeners = async () => {
      resumeListener = await App.addListener('appStateChange', async (state) => {
        if (!state.isActive) {
          localStorage.setItem(LOCALSTORAGE_KEY, Date.now().toString());
          if (!Preferences.get({ key: 'lastUsage' })) {
            await Preferences.set({
              key: 'lastUsage',
              // date in 3 months
              value: (Date.now() + 1000 * 60 * 60 * 24 * 30 * 3).toString(),
              //value: Date.now().toString(),
            });
          }
        } else {
          clearCacheMap();

          const storedTime = localStorage.getItem(LOCALSTORAGE_KEY);

          if (storedTime) {
            const lastClosed = parseInt(storedTime, 10);
            const now = Date.now();
            const elapsedMinutes = (now - lastClosed) / 60000;

            console.warn(`App was closed for ${elapsedMinutes.toFixed(2)} minutes`);
            if (elapsedMinutes > 30) {
              location.reload();
            }
            Preferences.get({ key: 'lastUsage' }).then(async (res) => {
              console.warn('lastUsage', res.value);
              if (res.value) {
                const lastUsage = parseInt(res.value, 10);
                const now = Date.now();
                //if now > lastUsage, it means the app was closed for more than 3 months
                if (now > lastUsage) {
                  await AppReview.requestReview();
                  await Preferences.set({
                    key: 'lastUsage',
                    // date in 3 months
                    value: (Date.now() + 1000 * 60 * 60 * 24 * 30 * 3).toString(),
                  });
                }
              }
            });
          }
        }
      });
    };

    if (Capacitor.isNativePlatform()) {
      setupListeners();
    }

    return () => {
      resumeListener?.remove();
    };
  }, [dispatch]);

  useEffect(() => {
    const requestTrackingPermission = async () => {
      try {
        const status = await AppTrackingTransparency.requestPermission();
        console.warn('Tracking status:', status);

        if (status.status === 'authorized') {
          console.warn('Tracking authorized');
          // You can now track the user
        } else {
          console.warn('Tracking not authorized');
        }
      } catch (error) {
        console.error('Error requesting tracking permission:', error);
      }
    };

    if (Capacitor.isNativePlatform()) {
      App.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          App.exitApp(); // Close app if there's no back history
        } else {
          window.history.back();
        }
      });

      requestTrackingPermission();
    }
  }, []);
};

export default useAppLifecycle;
