import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { NumbersType } from 'types/misc';
import type { PostType } from 'types/post';

export const fetchHomeIndex = createAsyncThunk<{ posts: PostType[]; magazine: PostType[] }, void>(
  'home/numbers/fetchHomeIndex',
  async (_, { rejectWithValue }) => {
    const response = await callTk<{ posts: PostType[]; magazine: PostType[] }>(
      'home',
      { method: 'GET' },
      (error) => rejectWithValue(error),
      false,
    );
    return response;
  },
);

export const fetchHomeNumbers = createAsyncThunk<{ numbers: NumbersType }, void>(
  'home/numbers/fetchHomeNumbers',
  async (_, { rejectWithValue }) => {
    const response = await callTk<{ numbers: NumbersType }>(
      'home/numbers',
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const homeNumbersSlice = createSlice({
  name: 'homeNumbers',
  initialState: {
    loading: false,
    error: false,
    numbers: null as NumbersType | null,
    posts: [] as PostType[],
    magazine: [] as PostType[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHomeIndex.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      fetchHomeIndex.fulfilled,
      (state, action: PayloadAction<{ posts: PostType[]; magazine: PostType[] }>) => {
        state.loading = false;
        state.posts = action.payload.posts;
        state.magazine = action.payload.magazine;
      },
    );
    builder.addCase(fetchHomeIndex.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchHomeNumbers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchHomeNumbers.fulfilled, (state, action: PayloadAction<{ numbers: NumbersType }>) => {
      state.loading = false;
      state.numbers = action.payload.numbers;
    });
    builder.addCase(fetchHomeNumbers.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const homeNumbersReducer = homeNumbersSlice.reducer;
