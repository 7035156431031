import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call, callTk, clearCacheMap } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import { italianDateToJSDate } from 'helpers/utils';
import type { CompetitionAdvancedSearchResultType } from 'types/competition';
import type {
  FetchRankParamsType,
  OpponentType,
  RankCallType,
  SetType,
  UserMatchCallType,
  UserMatchFetchCallType,
  UserMatchSaveParamsType,
  UserMatchType,
} from 'types/match';
import type { Rank } from 'types/player';
import { v4 as uuidv4 } from 'uuid';

export const getResultBySets = (setsResult: SetType[]) =>
  setsResult
    .filter((set: any) => ['home', 'away'].every((prop) => set[prop] && /^\s*\d*\s*$/.test(set[prop])))
    .map((set) => `${set.home.trim()}-${set.away.trim()}`)
    .join(' ');

const setResultStringToObject: (s: string) => SetType = (s) => {
  const [home, away] = s.split(s.includes('-') ? '-' : '');
  return {
    id: uuidv4(),
    home,
    away,
  };
};

export const fetchPlayersByQuery = createAsyncThunk<OpponentType[], string>(
  'fit-player-profiles/search/fetchPlayersByQuery',
  async (query, { rejectWithValue }) => {
    const response = await callTk<OpponentType[]>(
      `fit-player-profiles/search?query=${query}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchPlayersByQueryForTeammates = createAsyncThunk<OpponentType[], string>(
  'fit-player-profiles/search/fetchPlayersByQueryForTeammates',
  async (query, { rejectWithValue }) => {
    const response = await callTk<OpponentType[]>(
      `fit-player-profiles/search?query=${query}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchCompetitionsByQueryForTournamentName = createAsyncThunk<
  CompetitionAdvancedSearchResultType[],
  string
>('competitions/search/fetchCompetitionsByQueryForTournamentName', async (query, { rejectWithValue }) => {
  const response = await callTk<CompetitionAdvancedSearchResultType[]>(
    `competitions/search?query=${query}`,
    { method: 'GET' },
    (error) => rejectWithValue(error),
    true,
  );
  return response;
});

export const fetchPlayersByQueryForSecondOpponents = createAsyncThunk<OpponentType[], string>(
  'fit-player-profiles/search/fetchPlayersByQueryForSecondOpponents',
  async (query, { rejectWithValue }) => {
    const response = await callTk<OpponentType[]>(
      `fit-player-profiles/search?query=${query}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchRankByPlayerAndYear = createAsyncThunk<RankCallType, FetchRankParamsType>(
  'fit-player-profiles/rank/fetchRankByPlayerAndYear',
  async ({ fitPlayerId, year }, { rejectWithValue }) => {
    const response = await callTk<RankCallType>(
      `fit-player-profiles/${fitPlayerId}/rank?year=${year}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const saveMatch = createAsyncThunk<UserMatchCallType, UserMatchSaveParamsType & { callBack: () => void }>(
  'user-matches/saveMatch',
  async (
    {
      date,
      opponentName,
      selectedOpponent,
      won,
      tournamentName,
      boardName,
      setsResult,
      opponentRank,
      selectedTeammate,
      selectedSecondOpponent,
      teammateName,
      secondOpponentName,
      doubleEligibleForCalculation,
      callBack,
    },
    { rejectWithValue },
  ) => {
    clearCacheMap();
    const response = await call<UserMatchCallType>(
      'user-matches',
      {
        method: 'POST',
        body: JSON.stringify({
          user_match: {
            date,
            result: getResultBySets(setsResult),
            won,
            opponent_id: selectedOpponent,
            teammate_id: selectedTeammate,
            second_opponent_id: selectedSecondOpponent,
            opponent_rank_string: opponentRank,
            opponent_name: opponentName,
            teammate_name: teammateName,
            second_opponent_name: secondOpponentName,
            tournament: tournamentName,
            board: boardName,
            is_a_double_eligible_for_calculation: doubleEligibleForCalculation,
          },
        }),
      },
      (response) => {
        if (callBack) callBack();
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const fetchUserMatches = createAsyncThunk<UserMatchFetchCallType, number>(
  'user-matches/fetchUserMatches',
  async (page, { rejectWithValue }) => {
    const response = await callTk<UserMatchFetchCallType>(`user-matches?page=${page}`, { method: 'GET' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const confirmMatch = createAsyncThunk<UserMatchCallType, number>(
  'user-matches/confirmMatch',
  async (id, { rejectWithValue }) => {
    clearCacheMap();
    const response = await callTk<UserMatchCallType>(`user-matches/${id}/confirm`, { method: 'PUT' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const rejectMatch = createAsyncThunk<UserMatchCallType, number>(
  'user-matches/rejectMatch',
  async (id, { rejectWithValue }) => {
    clearCacheMap();
    const response = await callTk<UserMatchCallType>(`user-matches/${id}/reject`, { method: 'PUT' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const updateUserMatch = createAsyncThunk<UserMatchCallType, UserMatchSaveParamsType & { callBack: () => void }>(
  'user-matches/updateUserMatch',
  async (
    {
      id,
      date,
      opponentName,
      selectedOpponent,
      won,
      tournamentName,
      boardName,
      setsResult,
      opponentRank,
      secondOpponentName,
      teammateName,
      selectedTeammate,
      selectedSecondOpponent,
      doubleEligibleForCalculation,
      callBack,
    },
    { rejectWithValue },
  ) => {
    clearCacheMap();
    const response = await call<UserMatchCallType>(
      `user-matches/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          user_match: {
            date,
            result: getResultBySets(setsResult),
            won,
            opponent_name: opponentName,
            opponent_id: selectedOpponent,
            opponent_rank_string: opponentRank,
            tournament: tournamentName,
            board: boardName,
            second_opponent_id: selectedSecondOpponent,
            teammate_id: selectedTeammate,
            second_opponent_name: secondOpponentName,
            teammate_name: teammateName,
            is_a_double_eligible_for_calculation: doubleEligibleForCalculation,
          },
        }),
      },
      (response) => {
        callBack();
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const fetchMyUserMatches = createAsyncThunk<UserMatchFetchCallType, number>(
  'users/user-matches/fetchMyUserMatches',
  async (page, { rejectWithValue }) => {
    const response = await callTk<UserMatchFetchCallType>(
      `users/user-matches?page=${page}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const deleteUserMatch = createAsyncThunk<UserMatchCallType, number>(
  'user-matches/deleteUserMatch',
  async (id, { rejectWithValue }) => {
    clearCacheMap();
    const response = await callTk<UserMatchCallType>(`user-matches/${id}`, { method: 'DELETE' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const editUserMatch = createAsyncThunk<UserMatchType, number>(
  'user-matches/editUserMatch',
  async (id, { rejectWithValue }) => {
    const response = await callTk<UserMatchType>(`user-matches/${id}`, { method: 'GET' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const userMatchSlice = createSlice({
  name: 'userMatch',
  initialState: {
    error: false,
    loading: false,
    userMatches: [] as UserMatchType[],
    page: 1,
    lastPage: 1,
    opponents: null as OpponentType[] | null,
    teammates: null as OpponentType[] | null,
    competitions: null as CompetitionAdvancedSearchResultType[] | null,
    secondOpponents: null as OpponentType[] | null,
    opponentRank: '' as Rank,

    userMatch: null as UserMatchType | null,
    setsResult: [] as SetType[],
    date: null as string | null,
    selectedOpponent: null as number | null,
    selectedTeammate: null as number | null,
    selectedSecondOpponent: null as number | null,
    opponentName: '',
    teammateName: '',
    secondOpponentName: '',
    doubleEligibleForCalculation: false,
  },
  reducers: {
    clearUserMatches: (state) => {
      state.userMatches = [];
      state.page = 1;
      state.lastPage = 1;
    },
    clearOpponentRank: (state) => {
      state.opponentRank = '' as Rank;
    },
    editUserMatchField: (state, action: PayloadAction<{ field: string; value: any }>) => {
      const { field, value } = action.payload;
      (state.userMatch as any)[field] = value;
    },
    clearPlayers: (state) => {
      state.selectedOpponent = null;
      state.selectedTeammate = null;
      state.selectedSecondOpponent = null;
      state.opponentName = '';
      state.teammateName = '';
      state.secondOpponentName = '';
      state.doubleEligibleForCalculation = false;
    },
    updateDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    addResultSet: (state, action: PayloadAction<SetType>) => {
      state.setsResult.push(action.payload);
    },
    removeResultSet: (state, action: PayloadAction<number>) => {
      //TODO set type id è number o string?
      const index = state.setsResult.findIndex((set: any) => set.id === action.payload);
      state.setsResult.splice(index, 1);
    },
    changeResultSet: (state, action: PayloadAction<{ id: number | string; value: number | string; field: string }>) => {
      const { id, field, value } = action.payload;
      const newSetsResult = state.setsResult.map((set) =>
        set.id === id ? Object.assign({}, set, { [field]: value }) : set,
      );
      state.setsResult = newSetsResult;
    },
    eraseOpponent: (state) => {
      state.selectedOpponent = null;
      state.opponentName = '';
    },
    setOpponent: (state, action: PayloadAction<{ id: number; name: string }>) => {
      state.selectedOpponent = action.payload.id;
      state.opponentName = action.payload.name;
    },
    updateOpponentName: (state, action: PayloadAction<string>) => {
      state.opponentName = action.payload;
    },
    clearForm: (state) => {
      state.userMatch = null;
      state.setsResult = [];
      state.date = null;
      state.selectedOpponent = null;
      state.selectedTeammate = null;
      state.selectedSecondOpponent = null;
      state.opponentName = '';
      state.teammateName = '';
      state.secondOpponentName = '';
      state.doubleEligibleForCalculation = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlayersByQuery.pending, (state) => {
      state.error = false;
    });
    builder.addCase(fetchPlayersByQuery.fulfilled, (state, action: PayloadAction<OpponentType[]>) => {
      state.opponents = action.payload;
    });
    builder.addCase(fetchPlayersByQuery.rejected, (state) => {
      state.error = true;
      errorToastMessage("Si è verificato un errore nel trovare l'avversario.");
    });
    builder.addCase(fetchPlayersByQueryForTeammates.pending, (state) => {
      state.error = false;
    });
    builder.addCase(fetchPlayersByQueryForTeammates.fulfilled, (state, action: PayloadAction<OpponentType[]>) => {
      state.teammates = action.payload;
    });
    builder.addCase(fetchPlayersByQueryForTeammates.rejected, (state) => {
      state.error = true;
      errorToastMessage('Si è verificato un errore nel trovare il compagno di doppio.');
    });
    builder.addCase(fetchCompetitionsByQueryForTournamentName.pending, (state) => {
      state.error = false;
    });
    builder.addCase(
      fetchCompetitionsByQueryForTournamentName.fulfilled,
      (state, action: PayloadAction<CompetitionAdvancedSearchResultType[]>) => {
        state.competitions = action.payload;
      },
    );
    builder.addCase(fetchCompetitionsByQueryForTournamentName.rejected, (state) => {
      state.error = true;
      errorToastMessage('Si è verificato un errore nel trovare la competizione.');
    });
    builder.addCase(fetchPlayersByQueryForSecondOpponents.pending, (state) => {
      state.error = false;
    });
    builder.addCase(fetchPlayersByQueryForSecondOpponents.fulfilled, (state, action: PayloadAction<OpponentType[]>) => {
      state.secondOpponents = action.payload;
    });
    builder.addCase(fetchPlayersByQueryForSecondOpponents.rejected, (state) => {
      state.error = true;
      errorToastMessage("Si è verificato un errore nel trovare l'avversario del doppio.");
    });
    builder.addCase(fetchRankByPlayerAndYear.pending, (state) => {
      state.error = false;
    });
    builder.addCase(fetchRankByPlayerAndYear.fulfilled, (state, action: PayloadAction<RankCallType>) => {
      state.opponentRank = action.payload.rank;
    });
    builder.addCase(fetchRankByPlayerAndYear.rejected, (state) => {
      state.error = true;
      errorToastMessage("Si è verificato un errore nel trovare la classifica per l'anno selezionato.");
    });
    builder.addCase(saveMatch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(saveMatch.fulfilled, (state, action: PayloadAction<UserMatchCallType>) => {
      state.loading = false;
      const userMatches = [action.payload.user_match, ...state.userMatches];
      state.userMatches = userMatches;
      successToastMessage(
        'Partita inserita con successo. Sarà visibile solo a te fintanto che il tuo avversario non la confermerà',
      );
    });
    builder.addCase(saveMatch.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un errore nel trovare la classifica per l'anno selezionato.");
    });
    builder.addCase(fetchUserMatches.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchUserMatches.fulfilled, (state, action: PayloadAction<UserMatchFetchCallType>) => {
      state.loading = false;
      state.userMatches = action.payload.matches;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
    });
    builder.addCase(fetchUserMatches.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(confirmMatch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(confirmMatch.fulfilled, (state, action: PayloadAction<UserMatchCallType>) => {
      state.loading = false;
      const index = state.userMatches.findIndex((match) => match.id === action.payload.user_match.id);
      state.userMatches[index].is_confirmed = true;
      state.userMatches[index].is_rejected = false;
      state.userMatches[index].is_pending = false;
      successToastMessage('Partita confermata con successo.');
    });
    builder.addCase(confirmMatch.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(rejectMatch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(rejectMatch.fulfilled, (state, action: PayloadAction<UserMatchCallType>) => {
      state.loading = false;
      const index = state.userMatches.findIndex((match) => match.id === action.payload.user_match.id);
      state.userMatches[index].is_confirmed = false;
      state.userMatches[index].is_rejected = true;
      state.userMatches[index].is_pending = false;
      successToastMessage('Partita rifiutata con successo.');
    });
    builder.addCase(rejectMatch.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(updateUserMatch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(updateUserMatch.fulfilled, (state, action: PayloadAction<UserMatchCallType>) => {
      state.loading = false;
      const userMatches = state.userMatches.map((userMatch) =>
        userMatch.id === action.payload.user_match.id ? action.payload.user_match : userMatch,
      );
      state.userMatches = userMatches;
      successToastMessage(
        'Partita modificata con successo. Sarà visibile solo a te fintanto che il tuo avversario non la confermerà',
      );
    });
    builder.addCase(updateUserMatch.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore aggiornando la partita');
    });
    builder.addCase(fetchMyUserMatches.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchMyUserMatches.fulfilled, (state, action: PayloadAction<UserMatchFetchCallType>) => {
      state.loading = false;
      if (state.userMatches !== null) {
        state.userMatches = state.userMatches.concat(action.payload.matches);
      }
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
    });
    builder.addCase(fetchMyUserMatches.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(deleteUserMatch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteUserMatch.fulfilled, (state, action: PayloadAction<UserMatchCallType>) => {
      state.loading = false;
      const userMatches = state.userMatches.filter((userMatch) => userMatch.id !== action.payload.user_match.id);
      state.userMatches = userMatches;
      successToastMessage('Partita eliminata con successo.');
    });
    builder.addCase(deleteUserMatch.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore eliminando la partita.');
    });
    builder.addCase(editUserMatch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(editUserMatch.fulfilled, (state, action: PayloadAction<UserMatchType>) => {
      state.loading = false;
      state.userMatch = action.payload;
      const setsResult = action.payload.result
        .split(action.payload.result.includes('/') ? '/' : ' ')
        .map(setResultStringToObject);
      state.setsResult = setsResult;
      const itDate = action.payload.date;
      const date = italianDateToJSDate(itDate);
      state.date = date.toISOString().split('T')[0];
      const selectedOpponent = action.payload.opponent.real_player ? action.payload.opponent.id : null;
      const opponentName = action.payload.opponent.name;
      state.opponentName = opponentName;
      state.selectedOpponent = selectedOpponent;
      const selectedTeammate = action.payload.teammate?.real_player ? action.payload.teammate.id : null;
      const teammateName = action.payload.teammate?.name;
      state.teammateName = teammateName || '';
      state.selectedTeammate = selectedTeammate;
      const secondOpponent = action.payload.second_opponent?.real_player ? action.payload.second_opponent.id : null;
      const secondOpponentName = action.payload.second_opponent?.name;
      state.secondOpponentName = secondOpponentName || '';
      state.selectedSecondOpponent = secondOpponent;
      state.doubleEligibleForCalculation = action.payload.is_a_double_eligible_for_calculation;
    });
    builder.addCase(editUserMatch.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore nel recupero match.');
    });
  },
});

export const {
  clearUserMatches,
  clearOpponentRank,
  editUserMatchField,
  clearPlayers,
  updateDate,
  addResultSet,
  clearForm,
  removeResultSet,
  changeResultSet,
  eraseOpponent,
  setOpponent,
  updateOpponentName,
} = userMatchSlice.actions;
export const userMatchReducer = userMatchSlice.reducer;
