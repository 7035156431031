import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface UIState {
  isInteractingWithCarousel: boolean;
}

const initialState: UIState = {
  isInteractingWithCarousel: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setInteractingWithCarousel: (state, action: PayloadAction<boolean>) => {
      state.isInteractingWithCarousel = action.payload;
    },
  },
});

export const { setInteractingWithCarousel } = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
