import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { FollowUnfollowParameters} from 'store/followUnfollow/followUnfollowSlice';
import { setFollowed, setUnfollowed } from 'store/followUnfollow/followUnfollowSlice';
import type {
  ChartExtractCallType,
  ChartExtractType,
  HarmonizedDataType,
  PlayerDetailType,
  PlayerStatsType,
} from 'types/player';

const FOLLOWABLE_TYPE = 'fit_player_profile';

export const fetchPlayerProfile = createAsyncThunk<PlayerDetailType | undefined, number | string | undefined>(
  'fit-player-profiles/fetchPlayerProfile',
  async (fitPlayerId, { rejectWithValue }) => {
    if (fitPlayerId) {
      const response = await callTk<PlayerDetailType>(
        `fit-player-profiles/${fitPlayerId}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return undefined;
  },
);

export const fetchPlayerStats = createAsyncThunk<PlayerStatsType | null, number | string | undefined>(
  'fit-player-profiles/fetchPlayerStats',
  async (fitPlayerId, { rejectWithValue }) => {
    if (fitPlayerId) {
      const response = await callTk<PlayerStatsType>(
        `fit-player-profiles/${fitPlayerId}/stats`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return null;
  },
);

export const fetchHarmonizedRanking = createAsyncThunk<HarmonizedDataType | null, number | string | undefined>(
  'fit-player-profiles/fetchHarmonizedRanking',
  async (fitPlayerId, { rejectWithValue }) => {
    if (fitPlayerId) {
      const response = await callTk<HarmonizedDataType>(
        `fit-player-profiles/${fitPlayerId}/harmonization`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return null;
  },
);

export const fetchUnlockChartExtract = createAsyncThunk<ChartExtractCallType | null, number | string | undefined>(
  'fit-player-profiles/fetchUnlockChartExtract',
  async (fitPlayerId, { rejectWithValue }) => {
    if (fitPlayerId) {
      const response = await callTk<ChartExtractCallType>(
        `fit-player-profiles/${fitPlayerId}/chart_extract`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return null;
  },
);

export const playerProfileSlice = createSlice({
  name: 'playerProfile',
  initialState: {
    error: false,
    loading: false,
    loadingChartExtract: false,
    loadingHarmonization: false,
    loadingStats: false,
    player: undefined as PlayerDetailType | undefined,
    chartExtract: [] as ChartExtractType[],
    harmonizedData: null as HarmonizedDataType | null,
    stats: null as PlayerStatsType | null,
  },
  reducers: {
    clearPlayerProfile: (state) => {
      state.player = undefined;
      state.chartExtract = [] as ChartExtractType[];
      state.harmonizedData = null;
      state.stats = null;
    },
    clearChartExtract: (state) => {
      state.chartExtract = [] as ChartExtractType[];
    },
    clearHarmonizedData: (state) => {
      state.harmonizedData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlayerProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchPlayerProfile.fulfilled, (state, action: PayloadAction<PlayerDetailType | undefined>) => {
      state.loading = false;
      state.player = action.payload;
    });
    builder.addCase(fetchPlayerProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchPlayerStats.pending, (state) => {
      state.loadingStats = true;
      state.error = false;
    });
    builder.addCase(fetchPlayerStats.fulfilled, (state, action: PayloadAction<PlayerStatsType | null>) => {
      state.loadingStats = false;
      state.stats = action.payload;
    });
    builder.addCase(fetchPlayerStats.rejected, (state) => {
      state.loadingStats = false;
      state.error = true;
    });
    builder.addCase(fetchHarmonizedRanking.pending, (state) => {
      state.loadingHarmonization = true;
      state.error = false;
    });
    builder.addCase(fetchHarmonizedRanking.fulfilled, (state, action: PayloadAction<HarmonizedDataType | null>) => {
      state.loadingHarmonization = false;
      state.harmonizedData = action.payload;
    });
    builder.addCase(fetchHarmonizedRanking.rejected, (state) => {
      state.loadingHarmonization = false;
      state.error = true;
    });
    builder.addCase(fetchUnlockChartExtract.pending, (state) => {
      state.loadingChartExtract = true;
      state.error = false;
    });
    builder.addCase(fetchUnlockChartExtract.fulfilled, (state, action: PayloadAction<ChartExtractCallType | null>) => {
      state.loadingChartExtract = false;
      state.chartExtract = action.payload?.chart_extract ?? [];
    });
    builder.addCase(fetchUnlockChartExtract.rejected, (state) => {
      state.loadingChartExtract = false;
      state.error = true;
    });
    builder.addCase(setFollowed, (state, action: PayloadAction<FollowUnfollowParameters>) => {
      const { id, type } = action.payload;
      if (type === FOLLOWABLE_TYPE && state.player?.id === id) {
        state.player.followed = true;
      }
    });
    builder.addCase(setUnfollowed, (state, action: PayloadAction<FollowUnfollowParameters>) => {
      const { id, type } = action.payload;
      if (type === FOLLOWABLE_TYPE && state.player?.id === id) {
        state.player.followed = false;
      }
    });
  },
});

export const { clearPlayerProfile, clearChartExtract, clearHarmonizedData } = playerProfileSlice.actions;
export const playerProfileReducer = playerProfileSlice.reducer;
