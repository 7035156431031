import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { currentUser, isCurrentUserPremium } from 'helpers/authentication';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';

export type ContactFormType = {
  problem: string;
  info: {
    tournamentLink: string;
    match: string;
    message: string;
    email: string;
    playerName: string;
    membership: boolean;
  };
};

export const send = createAsyncThunk<{ success: boolean }, ContactFormType>(
  'contacts/send',
  async ({ problem, info }, { rejectWithValue }) => {
    const response = await callTk<{ success: boolean }>(
      'contacts',
      {
        method: 'POST',
        body: JSON.stringify({
          info: {
            problem,
            tournament_link: info.tournamentLink,
            match: info.match,
            message: info.message,
            email: info.email,
            player_name: info.playerName,
            membership: info.membership,
          },
        }),
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

const getMail = () => {
  if (currentUser()?.email && !currentUser()?.email.includes('privaterelay')) {
    return currentUser()?.email;
  }
  return '';
};

export const contactSlice = createSlice({
  name: 'contacts',
  initialState: {
    error: false,
    loading: false,
    tournamentLink: '',
    match: '',
    message: '',
    email: getMail(),
    playerName: currentUser()?.player?.name ?? currentUser()?.name ?? '',
    membership: isCurrentUserPremium(),
  },
  reducers: {
    setContactField: (state, action: PayloadAction<{ field: string; value: string }>) => {
      (state as any)[action.payload.field] = action.payload.value;
    },
    clearContactForm: (state) => {
      state.tournamentLink = '';
      state.match = '';
      state.message = '';
      state.email = getMail();
      state.playerName = currentUser()?.player?.name ?? currentUser()?.name ?? '';
      state.membership = isCurrentUserPremium();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(send.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(send.fulfilled, (state) => {
      state.loading = false;
      successToastMessage('Messaggio inviato. Verrai ricontattato al più presto.');
      contactSlice.actions.clearContactForm();
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    });
    builder.addCase(send.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore, riprova più tardi.');
    });
  },
});

export const { setContactField } = contactSlice.actions;
export const contactReducer = contactSlice.reducer;
