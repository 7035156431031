import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Badge } from '@capawesome/capacitor-badge';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call, callTk } from 'api';
import type { RootState } from 'store';
import type { AppDispatch } from 'store/store';
import type { NotificationCounterType, NotificationPageType, NotificationType } from 'types/notification';

export const fetchNotificationsCounter = createAsyncThunk<NotificationCounterType, void>(
  'home/notifications/fetchNotificationsCounter',
  async (_, { rejectWithValue }) => {
    const response = await callTk<NotificationCounterType>('home/notifications', { method: 'GET' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const fetchNotifications = createAsyncThunk<NotificationPageType, number>(
  'users/notifications/fetchNotifications',
  async (page, { rejectWithValue }) => {
    const response = await callTk<NotificationPageType>(
      `users/notifications?page=${page}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const fetchNotificationsDropdown = createAsyncThunk<NotificationPageType, number>(
  'users/notifications/fetchNotificationsDropdown',
  async (page, { rejectWithValue }) => {
    const response = await callTk<NotificationPageType>(
      `users/notifications_dropdown?page=${page}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

const postNotificationDispatch = (dispatch: AppDispatch) => {
  dispatch(fetchNotificationsCounter());
};

export const markNotificationAsRead = createAsyncThunk<
  { success: boolean },
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('users/read_notification', async (id, { rejectWithValue, dispatch }) => {
  const response = await call<{ success: boolean }>(
    'users/read_notification',
    { method: 'PUT', body: JSON.stringify({ notification_id: id }) },
    (response) => {
      postNotificationDispatch(dispatch);
      return response;
    },
    (error) => rejectWithValue(error),
  );
  return response;
});

export const checkPermissions = async () => {
  if (Capacitor.isNativePlatform()) {
    const permissionStatus = await Badge.checkPermissions();
    if (permissionStatus.display !== 'granted') {
      await Badge.requestPermissions();
    }
  }
};

export const setBadge = async (count: number) => {
  if (Capacitor.isNativePlatform()) {
    await Badge.set({ count });
  }
};

export const clearBadge = async () => {
  if (Capacitor.isNativePlatform()) {
    await Badge.clear();
  }
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    loading: false,
    error: false,
    counter: 0,
    message_counter: 0,
    data: [] as NotificationType[],
    page: 1,
    lastPage: 1,
  },
  reducers: {
    clearNotificationsCounter: (state) => {
      state.counter = 0;
      clearBadge();
    },
    clearNotifications: (state) => {
      state.data = [];
      state.page = 1;
      state.lastPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationsCounter.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchNotificationsCounter.fulfilled, (state, action: PayloadAction<NotificationCounterType>) => {
      state.loading = false;
      state.counter = action.payload.counter;
      state.message_counter = action.payload.message_counter;
      checkPermissions();
      setBadge(action.payload.counter);
    });
    builder.addCase(fetchNotificationsCounter.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchNotifications.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action: PayloadAction<NotificationPageType>) => {
      state.loading = false;
      state.data = action.payload.notifications;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
      if (Capacitor.isNativePlatform()) {
        PushNotifications.removeAllDeliveredNotifications();
      }
    });
    builder.addCase(fetchNotifications.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchNotificationsDropdown.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchNotificationsDropdown.fulfilled, (state, action: PayloadAction<NotificationPageType>) => {
      state.loading = false;
      state.data = action.payload.notifications;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
    });
    builder.addCase(fetchNotificationsDropdown.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(markNotificationAsRead.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(markNotificationAsRead.fulfilled, (state) => {
      state.loading = false;
      if (Capacitor.isNativePlatform()) {
        PushNotifications.removeAllDeliveredNotifications();
        clearBadge();
      }
    });
    builder.addCase(markNotificationAsRead.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clearNotificationsCounter, clearNotifications } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
