import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call } from 'api';
import { setCurrentUser } from 'helpers/authentication';
import { hasAdditionalInformation } from 'helpers/hasAdditionalInformation';
import type { RootState } from 'store';
import { hideModal } from 'store/modals/modalsSlice';
import type { AppDispatch } from 'store/store';
import type { AppleLoginType, FacebookLoginType, LoginCallType, UserDetailType, UserRegisterType } from 'types/user';

const postLoginDispatch = (dispatch: AppDispatch) => {
  dispatch(hideModal('register-form'));
};

const postLoginActions = (user: UserDetailType) => {
  if (hasAdditionalInformation(user)) {
    window.location.href = '/';
  } else {
    window.location.href = `/registrati/informazioni-aggiuntive/${user.confirmation_token}`;
  }
};

export const userSignUp = createAsyncThunk<
  LoginCallType,
  UserRegisterType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('users/sign-up/userSignUp', async (user, { rejectWithValue, dispatch }) => {
  const response = await call<LoginCallType>(
    'users/sign-up',
    {
      method: 'POST',
      body: JSON.stringify({
        email: user.email,
        password: user.password,
        password_confirmation: user.passwordConfirmation,
      }),
    },
    (response) => {
      postLoginDispatch(dispatch);
      return response;
    },
    (error) => rejectWithValue(error),
  );
  return response;
});

export const facebookUserSignUp = createAsyncThunk<
  LoginCallType,
  FacebookLoginType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('sessions/facebook/facebookUserSignUp', async (facebookData, { rejectWithValue, dispatch }) => {
  const response = await call<LoginCallType>(
    'sessions/facebook',
    { method: 'POST', body: JSON.stringify(facebookData) },
    (response) => {
      postLoginDispatch(dispatch);
      return response;
    },
    (error) => rejectWithValue(error),
  );
  return response;
});

export const appleUserSignUp = createAsyncThunk<
  LoginCallType,
  AppleLoginType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('sessions/apple-web-auth/appleUserSignUp', async (appleData, { rejectWithValue, dispatch }) => {
  const response = await call<LoginCallType>(
    'sessions/apple-web-auth',
    { method: 'POST', body: JSON.stringify(appleData) },
    (response) => {
      postLoginDispatch(dispatch);
      return response;
    },
    (error) => rejectWithValue(error),
  );
  postLoginDispatch(dispatch);
  return response;
});

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: {
    error: '',
    loading: false,
  },
  reducers: {
    clearUserSignUpData: (state) => {
      state.loading = false;
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userSignUp.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(userSignUp.fulfilled, (state, action: PayloadAction<LoginCallType>) => {
      state.loading = false;
      postLoginActions(action.payload.user);
    });
    builder.addCase(userSignUp.rejected, (state, action) => {
      state.loading = false;
      const errors = action.payload as { [key: string]: string[] };
      Object.keys(errors).forEach((key) => {
        state.error += `${key}: ${errors[key].join('\n')}\n`;
      });
    });
    builder.addCase(facebookUserSignUp.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(facebookUserSignUp.fulfilled, (state, action: PayloadAction<LoginCallType>) => {
      state.loading = false;
      setCurrentUser(Object.assign({}, action.payload.user, { user_id: action.payload.user.id }));
      postLoginActions(action.payload.user);
    });
    builder.addCase(facebookUserSignUp.rejected, (state, action) => {
      state.loading = false;
      const errors = action.payload as { [key: string]: string[] };
      Object.keys(errors).forEach((key) => {
        state.error += `${key}: ${errors[key].join('\n')}\n`;
      });
    });
    builder.addCase(appleUserSignUp.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(appleUserSignUp.fulfilled, (state, action: PayloadAction<LoginCallType>) => {
      state.loading = false;
      setCurrentUser(Object.assign({}, action.payload.user, { user_id: action.payload.user.id }));
      postLoginActions(action.payload.user);
    });
    builder.addCase(appleUserSignUp.rejected, (state, action) => {
      state.loading = false;
      const errors = action.payload as { [key: string]: string[] };
      Object.keys(errors).forEach((key) => {
        state.error += `${key}: ${errors[key].join('\n')}\n`;
      });
    });
  },
});

export const { clearUserSignUpData } = signUpSlice.actions;
export const signUpReducer = signUpSlice.reducer;
