export const formatTime = (date: Date, format: Intl.DateTimeFormatOptions) =>
  date.toLocaleString(
    'it-IT',
    format || {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  );

export const getDateMonthsDistance = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
  return diffMonths;
};

export const timeOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const getDate = (published_at: number) => {
  const date = new Date(published_at * 1000);
  return date.toLocaleDateString('it', timeOptions);
};

export const getDateShort = (published_at: number) => {
  const date = new Date(published_at * 1000);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('it', options);
};
