import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { encodeToUrlQueryParameters } from 'helpers/utils';
import type { MessageParamsType, MessageType } from 'types/user';

export const fetchMessages = createAsyncThunk<MessageType[], MessageParamsType>(
  'messages/conversation/fetchMessages',
  async (params, { rejectWithValue }) => {
    const response = await callTk<MessageType[]>(
      `messages/conversation?${encodeToUrlQueryParameters(params)}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const blockUser = createAsyncThunk<{ success: boolean }, number>(
  'messages/blockUser',
  async (id, { rejectWithValue }) => {
    const response = await callTk<{ success: boolean }>(`messages/${id}/block`, { method: 'PUT' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    error: false,
    loading: false,
    messages: [] as MessageType[],
  },
  reducers: {
    messageSent: (state, action: PayloadAction<MessageType>) => {
      state.messages.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchMessages.fulfilled, (state, action: PayloadAction<MessageType[]>) => {
      state.loading = false;
      state.messages = action.payload;
    });
    builder.addCase(fetchMessages.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(blockUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(blockUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(blockUser.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { messageSent } = messagesSlice.actions;
export const messagesReducer = messagesSlice.reducer;
