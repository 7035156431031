import { Navigate } from 'react-router-dom';
import { isLoggedIn, currentUser } from 'helpers/authentication';
import { hasAdditionalInformation } from 'helpers/hasAdditionalInformation';
import type { ReactNode } from 'react';

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const auth = isLoggedIn(); // determine if authorized, from context or however you're doing it
  if (auth) {
    const user = currentUser();
    if (hasAdditionalInformation(user)) {
      return children;
    } else {
      const token = user.confirmation_token;
      const redirectPath = `/registrati/informazioni-aggiuntive/${token}`;
      return <Navigate to={{ pathname: redirectPath }} replace />;
    }
  }
  if (window.location.pathname) {
    localStorage.setItem('loginredirect', window.location.pathname);
  }
  return <Navigate to='/accedi' />;
};
export default PrivateRoute;
