import { useScript } from 'helpers/utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LoadAdvAdKaora = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.adkSpaReload === 'function') {
      window.adkSpaReload();
    }
  }, [location]);

  //useScript('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4321911283255728');
  const loaded = useScript('https://cdn.adkaora.space/tennistalker/generic/prod/adk-init.js');
  return loaded ? <></> : <></>;
};

export default LoadAdvAdKaora;
