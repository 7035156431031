import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { UserPreviewType } from 'types/user';

export const fetchUserPreview = createAsyncThunk<UserPreviewType, string>(
  'users/preview/fetchUserPreview',
  async (userId, { rejectWithValue }) => {
    const response = await callTk<UserPreviewType>(`users/${userId}/preview`, { method: 'GET' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const userPreviewSlice = createSlice({
  name: 'userPreview',
  initialState: {
    name: '',
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserPreview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserPreview.fulfilled, (state, action: PayloadAction<UserPreviewType>) => {
      state.loading = false;
      state.name = action.payload.name;
    });
    builder.addCase(fetchUserPreview.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const userPreviewReducer = userPreviewSlice.reducer;
