import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';

export const confirmAccount = createAsyncThunk<{ success: boolean; errors: string[] }, string | undefined>(
  'users/confirm-account/confirmAccount',
  async (confirmationToken, { rejectWithValue }) => {
    if (confirmationToken) {
      const response = await callTk<{ success: boolean; errors: string[] }>(
        `users/confirm-account/${confirmationToken}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
      );
      return response;
    }
    return { success: false, errors: ["Non è stato possibile confermare l'account"] };
  },
);

export const accountConfirmationSlice = createSlice({
  name: 'accountConfirmation',
  initialState: {
    confirmed: false,
    loading: false,
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(confirmAccount.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(confirmAccount.fulfilled, (state) => {
      state.loading = false;
      state.confirmed = true;
    });
    builder.addCase(confirmAccount.rejected, (state, action) => {
      state.loading = false;
      state.confirmed = false;
      const errors = action.payload as string[];
      state.error = errors.join('\n');
    });
  },
});

export const accountConfirmationReducer = accountConfirmationSlice.reducer;
