import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { encodeToUrlQueryParameters } from 'helpers/utils';
import type { ConversationType, ProfileType } from 'types/user';

export const fetchConversations = createAsyncThunk<ConversationType[], ProfileType>(
  'users/messages-counterparts/fetchConversations',
  async (profile, { rejectWithValue }) => {
    const response = await callTk<ConversationType[]>(
      `users/messages-counterparts?${encodeToUrlQueryParameters(profile)}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: {
    error: false,
    loading: false,
    conversations: [] as ConversationType[],
    conversationIndex: 0,
  },
  reducers: {
    setActiveConversation: (state, action: PayloadAction<number>) => {
      state.conversationIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConversations.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchConversations.fulfilled, (state, action: PayloadAction<ConversationType[]>) => {
      state.loading = false;
      state.conversations = action.payload;
    });
    builder.addCase(fetchConversations.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { setActiveConversation } = conversationsSlice.actions;
export const conversationsReducer = conversationsSlice.reducer;
