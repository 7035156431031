import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';

export type FollowUnfollowResponse = {
  follow: {
    followable_id: number;
    followable_type: string;
  };
  success: boolean;
};

export type FollowUnfollowParameters = { id: number | string; type: string };

export const follow = createAsyncThunk<FollowUnfollowResponse, FollowUnfollowParameters>(
  'follows/follow',
  async ({ id, type }, { rejectWithValue, dispatch }) => {
    const response = await callTk<FollowUnfollowResponse>(
      'follows',
      {
        method: 'POST',
        body: JSON.stringify({
          followable_id: id,
          followable_type: type,
        }),
      },
      (error) => rejectWithValue(error),
    );
    dispatch(setFollowed({ id, type }));
    return response;
  },
);

export const unfollow = createAsyncThunk<FollowUnfollowResponse, FollowUnfollowParameters>(
  'remove-follow/unfollow',
  async ({ id, type }, { rejectWithValue, dispatch }) => {
    const response = await callTk<FollowUnfollowResponse>(
      'remove-follow',
      {
        method: 'POST',
        body: JSON.stringify({
          followable_id: id,
          followable_type: type,
        }),
      },
      (error) => rejectWithValue(error),
    );
    dispatch(setUnfollowed({ id, type }));
    return response;
  },
);

export const followUnfollowSlice = createSlice({
  name: 'followUnfollow',
  initialState: {
    error: false,
    loading: false,
  },
  reducers: {
    //here I dispatch the action to update the store of all the types followable
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFollowed: (_, action: PayloadAction<FollowUnfollowParameters>) => {},
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    setUnfollowed: (_, action: PayloadAction<FollowUnfollowParameters>) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(follow.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(follow.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(follow.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(unfollow.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(unfollow.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(unfollow.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { setFollowed, setUnfollowed } = followUnfollowSlice.actions;
export const followUnfollowReducer = followUnfollowSlice.reducer;
