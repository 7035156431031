import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    opened: [] as string[],
  },
  reducers: {
    showModal: (state, action: PayloadAction<string>) => {
      state.opened = [...new Set(state.opened.concat(action.payload))];
    },
    hideModal: (state, action: PayloadAction<string>) => {
      if (state.opened.includes(action.payload)) {
        state.opened = state.opened.filter((modal) => modal !== action.payload);
      }
    },
  },
});

export const { showModal, hideModal } = modalsSlice.actions;
export const searchReducer = modalsSlice.reducer;
