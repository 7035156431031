import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call, callTk } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import { encodeToUrlQueryParameters } from 'helpers/utils';
import type { OfferType } from 'types/market';
import type { MatchDrawType } from 'types/match';
import type {
  AddCommentPayloadType,
  ClubFeedParamsType,
  CommentCallType,
  CommentParamsType,
  CommentType,
  EditCommentParamsType,
  FeedCallType,
  FeedMatchesType,
  FeedOffersType,
  FeedParamsType,
  FetchPostParamsType,
  NewPostCallType,
  PlayerFeedParamsType,
  PostModifyParamsType,
  PostType,
  UserFeedParamsType,
} from 'types/post';

const insertComment: (comments: CommentType[], parentCommentId: number, newComment: CommentType) => CommentType[] = (
  comments,
  parentCommentId,
  newComment,
) => {
  if (comments && comments.length) {
    const parentIndex = comments.findIndex((comment) => comment.id === parentCommentId);
    if (parentIndex >= 0) {
      return comments.map((comment) => {
        if (comment.id === parentCommentId) {
          return { ...comment, children: [...comment.children, newComment] };
        }
        return comment;
      });
    } else {
      return comments.map((comment) => ({
        ...comment,
        children: insertComment(comment.children, parentCommentId, newComment),
      }));
    }
  }
  return comments;
};

const updateComments: (comments: CommentType[], updatedComment: CommentType) => CommentType[] = (
  comments,
  updatedComment,
) => {
  if (comments && comments.length) {
    return comments.map((comment) => {
      if (comment.id === updatedComment.id) return updatedComment;
      else return { ...comment, children: updateComments(comment.children, updatedComment) };
    });
  }
  return comments;
};

const deleteComments: (comments: CommentType[], deletableCommentId: number) => CommentType[] = (
  comments,
  deletableCommentId,
) => {
  if (comments && comments.length) {
    return comments
      .filter((comment) => comment.id !== deletableCommentId)
      .map((comment) => ({ ...comment, children: deleteComments(comment.children, deletableCommentId) }));
  }
  return comments;
};

export const fetchFeed = createAsyncThunk<FeedCallType, FeedParamsType>(
  'users/feed/fetchFeed',
  async ({ profileId, profileType, page, all }, { rejectWithValue }) => {
    const response = await callTk<FeedCallType>(
      `users/feed?${encodeToUrlQueryParameters({ id: profileId, type: profileType, page, all })}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchFeedPosts = createAsyncThunk<FeedCallType, FeedParamsType>(
  'users/feed-posts/fetchFeedPosts',
  async ({ profileId, profileType, page, all }, { rejectWithValue }) => {
    const response = await callTk<FeedCallType>(
      `users/feed-posts?${encodeToUrlQueryParameters({ id: profileId, type: profileType, page, all })}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchFeedNews = createAsyncThunk<FeedCallType, number>(
  'users/feed-news/fetchFeedNews',
  async (page, { rejectWithValue }) => {
    const response = await callTk<FeedCallType>(
      `users/feed-news?${encodeToUrlQueryParameters({ page })}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchFeedBlog = createAsyncThunk<FeedCallType, number>(
  'feed_news/fetchFeedBlog',
  async (page, { rejectWithValue }) => {
    const response = await callTk<FeedCallType>(
      `feed_news?${encodeToUrlQueryParameters({ page })}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchFeedMatches = createAsyncThunk<FeedMatchesType, FeedParamsType>(
  'users/feed-matches/fetchFeedMatches',
  async ({ profileId, profileType, page, all }, { rejectWithValue }) => {
    const response = await callTk<FeedMatchesType>(
      `users/feed-matches?${encodeToUrlQueryParameters({ id: profileId, type: profileType, page, all })}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchFeedOffers = createAsyncThunk<FeedOffersType, FeedParamsType>(
  'users/feed-offers/fetchFeedOffers',
  async ({ profileId, profileType, page, all }, { rejectWithValue }) => {
    const response = await callTk<FeedOffersType>(
      `users/feed-offers?${encodeToUrlQueryParameters({ id: profileId, type: profileType, page, all })}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchPost = createAsyncThunk<PostType, string>('posts/fetchPost', async (id, { rejectWithValue }) => {
  const response = await callTk<PostType>(`posts/${id}`, { method: 'GET' }, (error) => rejectWithValue(error), true);
  return response;
});

export const fetchLastBlog = createAsyncThunk<PostType, void>(
  'posts/last_blog/fetchLastBlog',
  async (_, { rejectWithValue }) => {
    const response = await callTk<PostType>(
      'posts/last_blog',
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchPlayerFeed = createAsyncThunk<FeedCallType, PlayerFeedParamsType>(
  'fit-player-profiles/fetchPlayerFeed',
  async ({ fitPlayerId, page }, { rejectWithValue, dispatch }) => {
    if (!page) dispatch(clearFeed());
    const response = await callTk<FeedCallType>(
      `fit-player-profiles/${fitPlayerId}/feed?page=${page}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchClubFeed = createAsyncThunk<FeedCallType, ClubFeedParamsType>(
  'clubs/fetchClubFeed',
  async ({ clubId, page }, { rejectWithValue, dispatch }) => {
    if (!page) dispatch(clearFeed());
    const response = await callTk<FeedCallType>(
      `clubs/${clubId}/feed?page=${page}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchUserFeed = createAsyncThunk<FeedCallType, UserFeedParamsType>(
  'users/fetchUserFeed',
  async ({ userId, page }, { rejectWithValue, dispatch }) => {
    if (!page) dispatch(clearFeed());
    const response = await callTk<FeedCallType>(
      `users/${userId}/posts?page=${page}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchPosts = createAsyncThunk<FeedCallType, FetchPostParamsType>(
  'posts/fetchPosts',
  async (params, { rejectWithValue, dispatch }) => {
    if (!params?.page) dispatch(clearFeed());
    const response = await callTk<FeedCallType>(
      `posts?${encodeToUrlQueryParameters(params)}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const editPost = createAsyncThunk<NewPostCallType, PostModifyParamsType>(
  'posts/editPost',
  async (params, { rejectWithValue }) => {
    const response = await callTk<NewPostCallType>(
      `posts/${params.id}`,
      { method: 'PUT', body: JSON.stringify(params) },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const unpublish = createAsyncThunk<NewPostCallType, number>(
  'posts/unpublish',
  async (postId, { rejectWithValue }) => {
    const response = await callTk<NewPostCallType>(`posts/${postId}`, { method: 'DELETE' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const likePost = createAsyncThunk<NewPostCallType, number>(
  'posts/likePost',
  async (postId, { rejectWithValue }) => {
    const response = await call<NewPostCallType>(
      `posts/${postId}/like`,
      { method: 'POST' },
      (response) => response,
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const unlikePost = createAsyncThunk<NewPostCallType, number>(
  'posts/unlikePost',
  async (postId, { rejectWithValue }) => {
    const response = await call<NewPostCallType>(
      `posts/${postId}/unlike`,
      { method: 'DELETE' },
      (response) => response,
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const createComment = createAsyncThunk<CommentCallType, CommentParamsType>(
  'posts/createComment',
  async (params, { rejectWithValue, dispatch }) => {
    const response = await call<CommentCallType>(
      `posts/${params.postId}/comments`,
      { method: 'POST', body: JSON.stringify(params) },
      (response: CommentCallType) => {
        dispatch(addComment({ postId: params.postId, parent: params.parent_id, comment: response.comment }));
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const editComment = createAsyncThunk<CommentCallType, EditCommentParamsType>(
  'posts/editComment',
  async (params, { rejectWithValue, dispatch }) => {
    const response = await call<CommentCallType>(
      `posts/${params.postId}/comments/${params.commentId}`,
      { method: 'PUT', body: JSON.stringify(params) },
      (response: CommentCallType) => {
        dispatch(updateComment({ postId: params.postId, comment: response.comment }));
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const likeComment = createAsyncThunk<CommentCallType, { commentId: number; postId: number }>(
  'posts/likeComment',
  async (params, { rejectWithValue, dispatch }) => {
    const response = await call<CommentCallType>(
      `comments/${params.commentId}/like`,
      { method: 'POST' },
      (response: CommentCallType) => {
        dispatch(updateComment({ postId: params.postId, comment: response.comment }));
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const unlikeComment = createAsyncThunk<CommentCallType, { commentId: number; postId: number }>(
  'posts/unlikeComment',
  async (params, { rejectWithValue, dispatch }) => {
    const response = await call<CommentCallType>(
      `comments/${params.commentId}/unlike`,
      { method: 'DELETE' },
      (response: CommentCallType) => {
        dispatch(updateComment({ postId: params.postId, comment: response.comment }));
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const deleteComment = createAsyncThunk<CommentCallType, EditCommentParamsType>(
  'posts/deleteComment',
  async ({ postId, commentId }, { rejectWithValue, dispatch }) => {
    const response = await call<CommentCallType>(
      `posts/${postId}/comments/${commentId}`,
      { method: 'DELETE' },
      (response: CommentCallType) => {
        dispatch(delComment({ postId: postId, comment: response.comment }));
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const feedSlice = createSlice({
  name: 'feed',
  initialState: {
    error: false,
    loading: false,
    page: 1,
    lastPage: 1,
    posts: [] as PostType[],
    matches: [] as MatchDrawType[],
    offers: [] as OfferType[],
  },
  reducers: {
    clearFeed: (state) => {
      state.error = false;
      state.loading = false;
      state.page = 1;
      state.lastPage = 1;
      state.posts = [];
    },
    clearFeedMatches: (state) => {
      state.error = false;
      state.loading = false;
      state.page = 1;
      state.lastPage = 1;
      state.matches = [];
    },
    clearFeedOffers: (state) => {
      state.error = false;
      state.loading = false;
      state.page = 1;
      state.lastPage = 1;
      state.offers = [];
    },
    postCreated: (state, action: PayloadAction<PostType>) => {
      state.posts.unshift(action.payload);
    },
    addComment: (state, action: PayloadAction<AddCommentPayloadType>) => {
      const postIndex = state.posts.findIndex((post) => post.id === action.payload.postId);
      if (postIndex === -1) {
        //case comment from homepage - state from homeNumbers
        successToastMessage('Commento aggiunto con successo.');
        setTimeout(() => {
          window.location.href = '/feed';
        }, 1000);
        return;
      }
      if (action.payload.parent === undefined) {
        state.posts[postIndex].comments.push(action.payload.comment);
      } else {
        state.posts[postIndex].comments = insertComment(
          state.posts[postIndex].comments,
          action.payload.parent,
          action.payload.comment,
        );
      }
    },
    updateComment: (state, action: PayloadAction<AddCommentPayloadType>) => {
      const postIndex = state.posts.findIndex((post) => post.id === action.payload.postId);
      state.posts[postIndex].comments = updateComments(state.posts[postIndex].comments, action.payload.comment);
    },
    delComment: (state, action: PayloadAction<AddCommentPayloadType>) => {
      const postIndex = state.posts.findIndex((post) => post.id === action.payload.postId);
      state.posts[postIndex].comments = deleteComments(state.posts[postIndex].comments, action.payload.comment.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeed.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFeed.fulfilled, (state, action: PayloadAction<FeedCallType>) => {
      state.loading = false;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
      state.posts.push(...action.payload.posts);
    });
    builder.addCase(fetchFeed.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFeedPosts.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFeedPosts.fulfilled, (state, action: PayloadAction<FeedCallType>) => {
      state.loading = false;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
      state.posts.push(...action.payload.posts);
    });
    builder.addCase(fetchFeedPosts.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFeedNews.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFeedNews.fulfilled, (state, action: PayloadAction<FeedCallType>) => {
      state.loading = false;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
      state.posts.push(...action.payload.posts);
    });
    builder.addCase(fetchFeedNews.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFeedBlog.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFeedBlog.fulfilled, (state, action: PayloadAction<FeedCallType>) => {
      state.loading = false;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
      state.posts.push(...action.payload.posts);
    });
    builder.addCase(fetchFeedBlog.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFeedMatches.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFeedMatches.fulfilled, (state, action: PayloadAction<FeedMatchesType>) => {
      state.loading = false;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
      state.matches.push(...action.payload.matches);
    });
    builder.addCase(fetchFeedMatches.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFeedOffers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchFeedOffers.fulfilled, (state, action: PayloadAction<FeedOffersType>) => {
      state.loading = false;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
      state.offers.push(...action.payload.offers);
    });
    builder.addCase(fetchFeedOffers.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchPost.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchPost.fulfilled, (state, action: PayloadAction<PostType>) => {
      state.loading = false;
      state.posts.push(action.payload);
    });
    builder.addCase(fetchPost.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchLastBlog.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchLastBlog.fulfilled, (state, action: PayloadAction<PostType>) => {
      state.loading = false;
      state.posts.push(action.payload);
    });
    builder.addCase(fetchLastBlog.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchPlayerFeed.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchPlayerFeed.fulfilled, (state, action: PayloadAction<FeedCallType>) => {
      state.loading = false;
      state.posts.push(...action.payload.posts);
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
    });
    builder.addCase(fetchPlayerFeed.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchClubFeed.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchClubFeed.fulfilled, (state, action: PayloadAction<FeedCallType>) => {
      state.loading = false;
      state.posts.push(...action.payload.posts);
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
    });
    builder.addCase(fetchClubFeed.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchUserFeed.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchUserFeed.fulfilled, (state, action: PayloadAction<FeedCallType>) => {
      state.loading = false;
      state.posts.push(...action.payload.posts);
      state.lastPage = action.payload.total_pages;
      state.page = action.payload.page;
    });
    builder.addCase(fetchUserFeed.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchPosts.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchPosts.fulfilled, (state, action: PayloadAction<FeedCallType>) => {
      state.loading = false;
      state.posts.push(...action.payload.posts);
    });
    builder.addCase(fetchPosts.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(editPost.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(editPost.fulfilled, (state, action: PayloadAction<NewPostCallType>) => {
      state.loading = false;
      const index = state.posts.findIndex((post) => post.id === action.payload.post.id);
      if (index !== -1) {
        state.posts[index] = action.payload.post;
      } else {
        setTimeout(() => {
          window.location.href = '/feed';
        }, 1000);
      }
    });
    builder.addCase(editPost.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(unpublish.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(unpublish.fulfilled, (state, action: PayloadAction<NewPostCallType>) => {
      state.loading = false;
      const index = state.posts.findIndex((post) => post.id === action.payload.post.id);
      if (index !== -1) {
        state.posts = state.posts.filter((post) => post.id !== action.payload.post.id);
      } else {
        setTimeout(() => {
          window.location.href = '/feed';
        }, 1000);
      }
    });
    builder.addCase(unpublish.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(likePost.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(likePost.fulfilled, (state, action: PayloadAction<NewPostCallType>) => {
      state.loading = false;
      if (action.payload.success) {
        const index = state.posts.findIndex((post) => post.id === action.payload.post.id);
        if (index !== -1) {
          state.posts[index] = action.payload.post;
        } else {
          setTimeout(() => {
            window.location.href = '/feed';
          }, 1000);
        }
      }
    });
    builder.addCase(likePost.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore nel mettere like al post.');
    });
    builder.addCase(unlikePost.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(unlikePost.fulfilled, (state, action: PayloadAction<NewPostCallType>) => {
      state.loading = false;
      if (action.payload.success) {
        const index = state.posts.findIndex((post) => post.id === action.payload.post.id);
        if (index !== -1) {
          state.posts[index] = action.payload.post;
        } else {
          setTimeout(() => {
            window.location.href = '/feed';
          }, 1000);
        }
      }
    });
    builder.addCase(unlikePost.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore nel togliere il like al post.');
    });
    builder.addCase(likeComment.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(likeComment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(likeComment.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore nel mettere like al commento.');
    });
    builder.addCase(unlikeComment.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(unlikeComment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(unlikeComment.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore nel togliere il like al commento.');
    });
    builder.addCase(createComment.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(createComment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createComment.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Si è verificato un errore nella creazione del commento.');
    });
    builder.addCase(editComment.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(editComment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editComment.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un errore nell'aggiornamento del commento.");
    });
    builder.addCase(deleteComment.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteComment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteComment.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un errore nell'eliminazione del commento.");
    });
  },
});

export const { clearFeed, clearFeedMatches, clearFeedOffers, postCreated, addComment, updateComment, delComment } =
  feedSlice.actions;
export const feedReducer = feedSlice.reducer;
