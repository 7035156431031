import { useEffect } from 'react';
import type { Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { useNavigate } from 'react-router-dom';
import { saveAppToken } from 'store/editProfile/editProfileSlice';
import { useAppDispatch } from 'store/store';
import { Capacitor } from '@capacitor/core';
import { isLoggedIn } from './authentication';
import { fetchNotificationsCounter } from 'store/notifications/notificationsSlice';
import type { NotificationType } from 'types/notification';
import { openInExternalUrl } from './utils';

export const usePushNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initPushNotifications = async () => {
      try {
        const result = await PushNotifications.requestPermissions();
        if (result.receive === 'granted') {
          // Register with Apple/Google for push notifications
          await PushNotifications.register();

          // Listen for successful registration
          PushNotifications.addListener('registration', (token: Token) => {
            console.warn('Firebase token to send to db: put to app-token-info', token.value);
            dispatch(saveAppToken({ token: token.value, os: Capacitor.getPlatform() }));
          });

          // Handle registration errors
          PushNotifications.addListener('registrationError', (error) => {
            console.error(`Error on registration: ${JSON.stringify(error)}`);
          });

          // Handle receiving a push notification
          PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            console.warn(`Push received: ${JSON.stringify(notification)}`);
            dispatch(fetchNotificationsCounter());
          });

          // Handle when user taps on a notification
          PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
            const data: NotificationType = notification.notification.data;
            if (data.destination_url) {
              //if is a path, navigate. If is a url, open in external browser
              if (data.destination_url.startsWith('/')) {
                navigate(data.destination_url);
              } else {
                openInExternalUrl(data.destination_url);
              }
            }
          });
        } else {
          console.warn('Push permission denied');
        }
      } catch (error) {
        console.error('Push notifications initialization error:', error);
      }
    };

    if (Capacitor.isNativePlatform() && isLoggedIn()) {
      initPushNotifications();
    }

    return () => {
      PushNotifications.removeAllListeners();
    };
    // eslint-disable-next-line
  }, [dispatch]);
};
