import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { currentUser, isLoggedIn, setCurrentUser } from 'helpers/authentication';
import type { EditGeneralInfoType, PreferredSurfacesType, UserDetailType } from 'types/user';

export type FacebookInfoType = { first_name: string; last_name: string; photo: string };

export const saveAdditionalInfo = createAsyncThunk<{ success: boolean; user: UserDetailType }, EditGeneralInfoType>(
  'users/sign-up/additional-info/saveAdditionalInfo',
  async (info, { rejectWithValue }) => {
    const response = await callTk<{ success: boolean; user: UserDetailType }>(
      'users/sign-up/additional-info',
      {
        method: 'POST',
        body: JSON.stringify({
          confirmation_token: info.token,
          first_name: info.firstName,
          last_name: info.lastName,
          birth_date: info.birthDate,
          phone: info.phone,
          address_user_input: info.address,
          photo: info.photo,
          bio: info.bio,
          preferred_surface: info.preferred_surface,
          marketing_communication_check: info.privacy,
          racket_brand: info.racket_brand,
          shoes_brand: info.shoes_brand,
          clothing_brand: info.clothing_brand,
          lefthanded: info.lefthanded,
          ig_id: info.ig_id,
          fb_id: info.fb_id,
          nickname: info.nickname,
        }),
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const fetchFacebookInfo = createAsyncThunk<FacebookInfoType, string | undefined>(
  'users/facebook-info/fetchFacebookInfo',
  async (token, { rejectWithValue }) => {
    if (token) {
      const response = await callTk<FacebookInfoType>(`users/facebook-info/${token}`, { method: 'GET' }, (error) =>
        rejectWithValue(error),
      );
      return response;
    }
    return { first_name: '', last_name: '', photo: '' };
  },
);

export const fetchPreferredSurfaces = createAsyncThunk<PreferredSurfacesType[]>(
  'users/preferred_surfaces/fetchPreferredSurfaces',
  async (token, { rejectWithValue }) => {
    const response = await callTk<PreferredSurfacesType[]>(
      'users/preferred_surfaces',
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const additionalInformationSlice = createSlice({
  name: 'additionalInformationSlice',
  initialState: {
    error: false,
    loading: false,
    facebookInfo: {
      firstName: '',
      lastName: '',
      photoUrl: '',
    },
    preferredSurfaces: [] as PreferredSurfacesType[],
  },
  reducers: {
    clearAdditionalInformationData: (state) => {
      state.error = false;
      state.loading = false;
      state.facebookInfo = {
        firstName: '',
        lastName: '',
        photoUrl: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAdditionalInfo.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      saveAdditionalInfo.fulfilled,
      (state, action: PayloadAction<{ success: boolean; user: UserDetailType }>) => {
        state.loading = false;
        if (isLoggedIn()) {
          const updatedUser = Object.assign({}, currentUser(), action.payload.user);
          setCurrentUser(updatedUser);
        }
        window.location.href = `/registrati/completa-profilo/${action.payload.user.confirmation_token}`;
      },
    );
    builder.addCase(saveAdditionalInfo.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchFacebookInfo.fulfilled, (state, action: PayloadAction<FacebookInfoType>) => {
      if (action.payload === undefined) return;
      state.facebookInfo = {
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        photoUrl: action.payload.photo,
      };
    });
    builder.addCase(fetchPreferredSurfaces.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchPreferredSurfaces.fulfilled, (state, action: PayloadAction<PreferredSurfacesType[]>) => {
      state.loading = false;
      state.preferredSurfaces = action.payload;
    });
    builder.addCase(fetchPreferredSurfaces.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clearAdditionalInformationData } = additionalInformationSlice.actions;
export const additionalInformationReducer = additionalInformationSlice.reducer;
