import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { VisitorCallType, VisitsCallType } from 'types/misc';
import type { FollowerUserType } from 'types/user';

export const fetchVisitorByUser = createAsyncThunk<VisitorCallType, number>(
  'fit-player-profiles/visitors/fetchVisitorByUser',
  async (fitPlayerId, { rejectWithValue }) => {
    const response = await callTk<VisitorCallType>(
      `fit-player-profiles/${fitPlayerId}/visitors`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchVisitsByUser = createAsyncThunk<VisitsCallType, number>(
  'fit-player-profiles/visits/fetchVisitsByUser',
  async (fitPlayerId, { rejectWithValue }) => {
    const response = await callTk<VisitsCallType>(
      `fit-player-profiles/${fitPlayerId}/visits`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const visitorsSlice = createSlice({
  name: 'visitors',
  initialState: {
    visitors: [] as FollowerUserType[],
    loading: false,
    error: false,
    views: -1,
    clear: false,
  },
  reducers: {
    clearVisits: (state) => {
      state.views = -1;
      state.visitors = [];
      state.clear = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVisitorByUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchVisitorByUser.fulfilled, (state, action: PayloadAction<VisitorCallType>) => {
      state.loading = false;
      state.visitors = action.payload.visitors;
    });
    builder.addCase(fetchVisitorByUser.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchVisitsByUser.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchVisitsByUser.fulfilled, (state, action: PayloadAction<VisitsCallType>) => {
      state.loading = false;
      state.views = action.payload.visits;
      state.clear = true;
    });
    builder.addCase(fetchVisitsByUser.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clearVisits } = visitorsSlice.actions;
export const visitorsReducer = visitorsSlice.reducer;
