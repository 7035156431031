import { Capacitor } from '@capacitor/core';
import {
  getAPIToken,
  logoutFrontend,
  getFingerPrint,
  getVisit,
  waitForProp,
  openBecomePremiumPage,
} from 'helpers/authentication';
import type { ErrorType, RequestData } from 'types/misc';

const callCache = new Map();

const handleCatch = (error: ErrorType, errorCallback: (error: ErrorType) => void) => {
  errorCallback(error);
};
const handleData = (response: void | Response) => {
  if (response?.status === 401) logoutFrontend();
  if (response?.status === 403) openBecomePremiumPage();

  if (!response || !response.ok || response.status >= 400 || response.status === 204) throw response;
  else {
    return response.clone().json();
  }
};

const handleResponse = (
  response: Response & { success?: boolean; errors?: string; error?: string },
  successCallback: (response: Response & { success?: boolean }) => void,
  data: RequestData,
) => {
  if (data?.method === 'PUT' || data?.method === 'DELETE') {
    clearCacheMap();
  }
  if (!response) throw 'Risposta non valida';
  if (response.success === false) {
    if (response?.errors) throw response.errors;
    if (response?.error) throw response.error;
    else throw 'Errore';
  }
  return successCallback(response);
};

const memoizePromiseFn =
  (fn: any) =>
  (...args: any[]) => {
    const key = JSON.stringify(args);

    if (callCache.has(key)) {
      return callCache.get(key);
    }

    callCache.set(
      key,
      fn(...args).catch((error: any) => {
        // Delete cache entry if API call fails
        callCache.delete(key);
        return Promise.reject(error);
      }),
    );

    return callCache.get(key);
  };

const getBaseUrl = () => {
  let baseUrl: string | undefined;

  switch (import.meta.env.VITE_ENV) {
    case 'production':
      baseUrl = import.meta.env.VITE_API_URL_PROD;
      break;
    case 'staging':
      baseUrl = import.meta.env.VITE_API_URL_STAGING;
      break;
    default:
      baseUrl = import.meta.env.VITE_API_URL;
  }
  if (Capacitor.isNativePlatform()) {
    baseUrl = baseUrl?.replace('/api/', '/app/');
  }
  return baseUrl;
};

export const callFn = async (url: string, data: RequestData) => {
  await waitForProp('fingerprint');
  const headers = new Headers({
    'X-Api-Key': getAPIToken() ?? '',
    Accept: 'application/json',
    'Ahoy-Visit': getVisit(),
    'Ahoy-Visitor': getFingerPrint() ?? '',
    'Content-Type': 'application/json',
  });
  const requestDetails = Object.assign({}, data, { headers: headers });
  return fetch(`${getBaseUrl()}${url}`, requestDetails);
};

const memoizedCallFn = memoizePromiseFn(callFn);

export const callTk = <T>(
  url: string,
  data: RequestData,
  errorCallback: (error: ErrorType) => void,
  searchCache = false,
): Promise<T> => call(url, data, undefined, errorCallback, searchCache);

export const call = async <T>(
  url: string,
  data: RequestData,
  successCallback = (resp: any) => resp,
  errorCallback: (error: ErrorType) => void,
  searchCache = false,
): Promise<T> => {
  const callFunction = searchCache ? memoizedCallFn : callFn;
  return await callFunction(url, data, successCallback, errorCallback)
    .catch((error: any) => handleCatch(error, errorCallback))
    .then((response: void | Response) => handleData(response))
    .then((response: any) => handleResponse(response, successCallback, data))
    .catch((error: ErrorType) => errorCallback(error));
};

export const clearCacheMap = () => {
  callCache.clear();
};
