import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call, callTk } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import { hideModal } from 'store/modals/modalsSlice';
import type { BonusCallType, BonusParamsType, BonusType, SaveBonusCallType } from 'types/user';

export const saveBonus = createAsyncThunk<SaveBonusCallType, BonusParamsType>(
  'bonuses/saveBonus',
  async (userBonus, { rejectWithValue, dispatch }) => {
    const response = await call<SaveBonusCallType>(
      'bonuses',
      {
        method: 'POST',
        body: JSON.stringify({
          bonus: userBonus,
        }),
      },
      (response) => {
        dispatch(hideModal('user-bonus-form-modal'));
        return response;
      },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const fetchBonuses = createAsyncThunk<BonusCallType, number>(
  'bonuses/fetchBonuses',
  async (page, { rejectWithValue }) => {
    const response = await callTk<BonusCallType>(
      `bonuses?page=${page}`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const deleteBonus = createAsyncThunk<SaveBonusCallType, number>(
  'bonuses/deleteBonus',
  async (id, { rejectWithValue }) => {
    const response = await callTk<SaveBonusCallType>(
      `bonuses/${id}`,
      { method: 'DELETE' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const userBonusSlice = createSlice({
  name: 'userBonus',
  initialState: {
    error: false,
    loading: false,
    bonuses: [] as BonusType[],
    page: 1,
    lastPage: 1,
  },
  reducers: {
    clearBonuses: (state) => {
      state.bonuses = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveBonus.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(saveBonus.fulfilled, (state, action: PayloadAction<SaveBonusCallType>) => {
      state.loading = false;
      state.bonuses.unshift(action.payload.bonus);
      successToastMessage('Bonus inserito con successo. Sarà visibile soltanto a te.');
    });
    builder.addCase(saveBonus.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Errore inserimento bonus.');
    });
    builder.addCase(fetchBonuses.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchBonuses.fulfilled, (state, action: PayloadAction<BonusCallType>) => {
      state.loading = false;
      state.bonuses = action.payload.bonuses;
      state.page = action.payload.page;
      state.lastPage = action.payload.total_pages;
    });
    builder.addCase(fetchBonuses.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(deleteBonus.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteBonus.fulfilled, (state, action: PayloadAction<SaveBonusCallType>) => {
      state.loading = false;
      state.bonuses = state.bonuses.filter((bonus) => bonus.id !== action.payload.bonus.id);
      successToastMessage('Bonus eliminato con successo.');
    });
    builder.addCase(deleteBonus.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage('Errore eliminazione bonus.');
    });
  },
});

export const { clearBonuses } = userBonusSlice.actions;
export const userBonusReducer = userBonusSlice.reducer;
