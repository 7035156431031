import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { FollowUnfollowParameters } from 'store/followUnfollow/followUnfollowSlice';
import { setFollowed, setUnfollowed } from 'store/followUnfollow/followUnfollowSlice';
import type { ClubPageType } from 'types/club';

const FOLLOWABLE_TYPE = 'club';

export const fetchClubProfile = createAsyncThunk<ClubPageType | null, number | string | undefined>(
  'clubs/fetchClubProfile',
  async (clubId, { rejectWithValue }) => {
    if (clubId) {
      const response = await callTk<ClubPageType>(
        `clubs/${clubId}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return null;
  },
);

export const clubProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    error: false,
    loading: false,
    club: null as ClubPageType | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClubProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchClubProfile.fulfilled, (state, action: PayloadAction<ClubPageType | null>) => {
      state.loading = false;
      state.club = action.payload;
    });
    builder.addCase(fetchClubProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(setFollowed, (state, action: PayloadAction<FollowUnfollowParameters>) => {
      const { id, type } = action.payload;
      if (type === FOLLOWABLE_TYPE && state.club && state.club.id === id) {
        state.club.followed = true;
      }
    });
    builder.addCase(setUnfollowed, (state, action: PayloadAction<FollowUnfollowParameters>) => {
      const { id, type } = action.payload;
      if (type === FOLLOWABLE_TYPE && state.club && state.club.id === id) {
        state.club.followed = false;
      }
    });
  },
});

export const clubProfileReducer = clubProfileSlice.reducer;
