import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import type { ReportCallType, ReportParameters } from 'types/post';

export const sendReport = createAsyncThunk<ReportCallType, ReportParameters>(
  'reports/sendReport',
  async (params, { rejectWithValue }) => {
    const response = await callTk<ReportCallType>(
      'reports',
      { method: 'POST', body: JSON.stringify(params) },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    error: false,
    loading: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendReport.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(sendReport.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
      successToastMessage('La segnalazione è stata inviata correttamente. Sarà gestita quanto prima.');
    });
    builder.addCase(sendReport.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Si è verificato un errore nell'invio della segnalazione.");
    });
  },
});

export const reportReducer = reportSlice.reducer;
