import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { TournamentType } from 'types/tournament';

export const fetchTournament = createAsyncThunk<TournamentType | null, string | undefined>(
  'tournaments/fetchTournament',
  async (id, { rejectWithValue }) => {
    if (id) {
      const response = await callTk<TournamentType>(
        `tournaments/${id}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return null;
  },
);

export const tournamentSlice = createSlice({
  name: 'tournament',
  initialState: {
    error: false,
    loading: false,
    tournament: null as TournamentType | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTournament.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTournament.fulfilled, (state, action: PayloadAction<TournamentType | null>) => {
      state.loading = false;
      state.tournament = action.payload;
    });
    builder.addCase(fetchTournament.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const tournamentReducer = tournamentSlice.reducer;
