import type { AdOptions } from '@capacitor-community/admob';
import { AdMob, AdmobConsentStatus } from '@capacitor-community/admob';
import { Capacitor } from '@capacitor/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const adIosInterstitial = 'ca-app-pub-7444012573713306/4219909122';
export const adIosRewarded = 'ca-app-pub-7444012573713306/2307281406';
export const adIosBanner = 'ca-app-pub-7444012573713306/9263656080';

export const adAndroidInterstitial = 'ca-app-pub-7444012573713306/4003506454';
export const adAndroidRewarded = 'ca-app-pub-7444012573713306/6438098101';
export const adAndroidBanner = 'ca-app-pub-7444012573713306/3879467462';

export const pathNameNoAds = [
  'accedi',
  'registrati',
  'conferma-account',
  'reimposta-password',
  'privacy',
  'password-dimenticata',
  'diventa-member',
  'club-membership',
];

// Function to check if the pathname should not display ads
export const shouldDisplayAds = (pathname: string) => !pathNameNoAds.some((path) => pathname.includes(path));

const LOCALSTORAGE_ADMOB_SHOWN_KEY = '0';
export const InitAdmob = () => {
  const [shown, setShown] = useState<number>(
    localStorage.getItem(LOCALSTORAGE_ADMOB_SHOWN_KEY)
      ? parseInt(localStorage.getItem(LOCALSTORAGE_ADMOB_SHOWN_KEY) as string)
      : 0,
  );
  const location = useLocation();

  useEffect(() => {
    const initialize: () => Promise<void> = async () => {
      await AdMob.initialize({ initializeForTesting: false });

      const [trackingInfo, consentInfo] = await Promise.all([
        AdMob.trackingAuthorizationStatus(),
        AdMob.requestConsentInfo(),
      ]);

      if (trackingInfo.status === 'notDetermined') {
        /**
         * If you want to explain TrackingAuthorization before showing the iOS dialog,
         * you can show the modal here.
         * ex)
         * const modal = await this.modalCtrl.create({
         *   component: RequestTrackingPage,
         * });
         * await modal.present();
         * await modal.onDidDismiss();  // Wait for close modal
         **/
        try {
          await AdMob.requestTrackingAuthorization();
        } catch (e) {
          console.warn(e);
        }
      }
      try {
        const authorizationStatus = await AdMob.trackingAuthorizationStatus();
        if (
          authorizationStatus.status === 'authorized' &&
          consentInfo.isConsentFormAvailable &&
          consentInfo.status === AdmobConsentStatus.REQUIRED
        ) {
          await AdMob.showConsentForm();
        }
      } catch (e) {
        console.warn(e);
      }
    };
    try {
      initialize();
    } catch (e) {
      console.warn(e);
    }
  }, []);

  useEffect(() => {
    const showInterstitial = async () => {
      try {
        const adId = Capacitor.getPlatform() === 'ios' ? adIosInterstitial : adAndroidInterstitial;
        const options: AdOptions = {
          adId: adId,
          isTesting: false,
          // npa: true
        };
        await AdMob.prepareInterstitial(options);
        await AdMob.showInterstitial();
      } catch (e) {
        console.warn(e);
      }
    };

    try {
      if (Date.now() - shown > 5 * 60 * 1000) {
        if (shouldDisplayAds(location.pathname)) {
          setShown(Date.now());
          localStorage.setItem(LOCALSTORAGE_ADMOB_SHOWN_KEY, Date.now().toString());
          showInterstitial();
        }
      }
    } catch (e) {
      console.warn(e);
    }

    // eslint-disable-next-line
  }, [location]);

  return <></>;
};
export default InitAdmob;
