import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { ClubSearchType } from 'types/club';
import type { CompetitionAdvancedSearchResultType } from 'types/competition';
import type { PlayerInlineSearchResultType } from 'types/player';
import type { InlineSearchCallType, InlineSearchPlayersCallType } from 'types/search';
import type { UserDetailShortType } from 'types/user';

export const inlineSearch = createAsyncThunk<InlineSearchCallType | void, string>(
  'home/inline-search/inlineSearch',
  async (query, { rejectWithValue }) => {
    if (query.length >= 4) {
      const response = await callTk<InlineSearchCallType>(
        'home/inline-search',
        {
          method: 'POST',
          body: JSON.stringify({
            q: query,
          }),
        },
        (error) => rejectWithValue(error),
      );
      return response;
    }
  },
);

export const inlineSearchPlayers = createAsyncThunk<InlineSearchPlayersCallType | void, string>(
  'home/inline-search/inlineSearchPlayers',
  async (query, { rejectWithValue }) => {
    if (query.length >= 4) {
      const response = await callTk<InlineSearchPlayersCallType>(
        'home/inline-search-players',
        {
          method: 'POST',
          body: JSON.stringify({
            q: query,
          }),
        },
        (error) => rejectWithValue(error),
      );
      return response;
    }
  },
);

export const inlineSearchSlice = createSlice({
  name: 'inlineSearch',
  initialState: {
    loading: false,
    error: false,
    query: '',
    players: [] as PlayerInlineSearchResultType[],
    competitions: [] as CompetitionAdvancedSearchResultType[],
    clubs: [] as ClubSearchType[],
    other_profiles: [] as UserDetailShortType[],
  },
  reducers: {
    setInlineSearchQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    clearInlineSearchResults: (state) => {
      state.query = '';
      state.players = [];
      state.competitions = [];
      state.clubs = [];
      state.other_profiles = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(inlineSearch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(inlineSearch.fulfilled, (state, action: PayloadAction<InlineSearchCallType | void>) => {
      state.loading = false;
      if (action.payload) {
        state.players = action.payload.results.players;
        state.competitions = action.payload.results.competitions;
        state.clubs = action.payload.results.clubs;
        state.other_profiles = action.payload.results.other_profiles;
      }
    });
    builder.addCase(inlineSearch.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(inlineSearchPlayers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      inlineSearchPlayers.fulfilled,
      (state, action: PayloadAction<InlineSearchPlayersCallType | void>) => {
        state.loading = false;
        if (action.payload) {
          state.players = action.payload.results.players;
        }
      },
    );
    builder.addCase(inlineSearchPlayers.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { setInlineSearchQuery, clearInlineSearchResults } = inlineSearchSlice.actions;
export const inlineSearchReducer = inlineSearchSlice.reducer;
