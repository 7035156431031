import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { OfferType } from 'types/market';

export const fetchOffer = createAsyncThunk<OfferType | null, string | undefined>(
  'offers/fetchOffer',
  async (offerId, { rejectWithValue }) => {
    if (offerId) {
      const response = await callTk<OfferType>(`offers/${offerId}`, { method: 'GET' }, (error) =>
        rejectWithValue(error),
      );
      return response;
    }
    return null;
  },
);

export const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    error: false,
    loading: false,
    offer: null as OfferType | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOffer.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchOffer.fulfilled, (state, action: PayloadAction<OfferType | null>) => {
      state.loading = false;
      state.offer = action.payload;
    });
    builder.addCase(fetchOffer.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const offerReducer = offerSlice.reducer;
