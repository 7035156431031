import type { Category, Rank } from 'types/player';

export default [
  '4.NC',
  '4.6',
  '4.5',
  '4.4',
  '4.3',
  '4.2',
  '4.1',
  '3.5',
  '3.4',
  '3.3',
  '3.2',
  '3.1',
  '2.8',
  '2.7',
  '2.6',
  '2.5',
  '2.4',
  '2.3',
  '2.2',
  '2.1',
  '1.20',
  '1.19',
  '1.18',
  '1.17',
  '1.16',
  '1.15',
  '1.14',
  '1.13',
  '1.12',
  '1.11',
  '1.10',
  '1.9',
  '1.8',
  '1.7',
  '1.6',
  '1.5',
  '1.4',
  '1.3',
  '1.2',
  '1.1',
];

export const mapValueRank: { [k: number]: string } = {
  1: '4.NC',
  2: '4.6',
  3: '4.5',
  4: '4.4',
  5: '4.3',
  6: '4.2',
  7: '4.1',
  8: '3.5',
  9: '3.4',
  10: '3.3',
  11: '3.2',
  12: '3.1',
  13: '2.8',
  14: '2.7',
  15: '2.6',
  16: '2.5',
  17: '2.4',
  18: '2.3',
  19: '2.2',
  20: '2.1',
  21: '1.20',
  22: '1.19',
  23: '1.18',
  24: '1.17',
  25: '1.16',
  26: '1.15',
  27: '1.14',
  28: '1.13',
  29: '1.12',
  30: '1.11',
  31: '1.10',
  32: '1.9',
  33: '1.8',
  34: '1.7',
  35: '1.6',
  36: '1.5',
  37: '1.4',
  38: '1.3',
  39: '1.2',
  40: '1.1',
};

export const mapRankValue: { [k: string]: number } = Object.entries(mapValueRank).reduce(
  (ret: { [k: string]: number }, [key, value]: [string, string]) => {
    ret[value] = parseInt(key);
    return ret;
  },
  {} as { [k: string]: number },
);

const getProportionalValue = (
  input: number,
  inputMin: number,
  inputMax: number,
  outputMin: number,
  outputMax: number,
) => {
  const output = ((input - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) + outputMin;
  return output;
};

export const getColorFromRank = (input: number) => {
  let inputMin = 1;
  let inputMax = 7;

  const outputMin = 20;
  const outputMax = 230;
  let red = 255;
  let green = 255;
  let blue = 200;

  if (input <= 7) {
    inputMin = 1;
    inputMax = 7;
    blue = getProportionalValue(input, inputMin, inputMax, outputMin, outputMax);
    green = blue;
  } else if (input <= 12) {
    inputMin = 8;
    inputMax = 12;
    green = getProportionalValue(input, inputMin, inputMax, outputMin, outputMax);
    red = green;
  } else {
    inputMin = 13;
    inputMax = 40;
    blue = getProportionalValue(input, inputMin, inputMax, outputMin, outputMax);
    red = blue;
  }

  // Return the RGB color in the format "rgb(red, green, blue)"
  return `rgba(${red}, ${green}, ${blue}, 0.8)`;
};

export const getColorFromRanks = (rank: Rank) => {
  switch (rank) {
    case '4.NC':
      return 'bg-[#7b7d7d]';
    case '4.6':
      return 'bg-[#9a7d0a]';
    case '4.5':
      return 'bg-[#b7950b]';
    case '4.4':
      return 'bg-[#d4ac0d]';
    case '4.3':
      return 'bg-[#f1c40f]';
    case '4.2':
      return 'bg-[#f5b041]';
    case '4.1':
      return 'bg-[#f39c12]';
    case '3.5':
      return 'bg-[#e67e22]';
    case '3.4':
      return 'bg-[#d35400]';
    case '3.3':
      return 'bg-[#e74c3c]';
    case '3.2':
      return 'bg-[#cb4335]';
    case '3.1':
      return 'bg-[#b03a2e]';
    case '2.8':
      return 'bg-[#7d6608]';
    case '2.7':
      return 'bg-[#aba02c]';
    case '2.6':
      return 'bg-[#81b642]';
    case '2.5':
      return 'bg-[#57cc58]';
    case '2.4':
      return 'bg-[#2ecc71]';
    case '2.3':
      return 'bg-[#1abc9c]';
    case '2.2':
      return 'bg-[#148f77]';
    case '2.1':
      return 'bg-[#0e6655]';
    default:
      return 'bg-neutral-neutral-12';
  }
};

export const fromCategoryToClass = (category: Category) => {
  switch (category) {
    case 'U08':
      return 'bg-[#85c1e9]';
    case 'U10':
      return 'bg-[#5dade2]';
    case 'U12':
      return 'bg-[#3498db]';
    case 'U14':
      return 'bg-[#2e86c1]';
    case 'U16':
      return 'bg-[#2874a6]';
    case 'U18':
      return 'bg-[#21618c]';
    case 'NOR':
      return 'bg-[#1b4f72]';
    case 'NOF':
      return 'bg-[#633974]';
    case 'O35':
      return 'bg-[#76448a]';
    case 'O40':
      return 'bg-[#884ea0]';
    case 'O45':
      return 'bg-[#9b59b6]';
    case 'O50':
      return 'bg-[#af7ac5]';
    case 'O55':
      return 'bg-[#c39bd3]';
    case 'O60':
      return 'bg-[#d98880]';
    case 'O65':
      return 'bg-[#e6b0aa]';
    case 'O70':
      return 'bg-gold-7';
    case 'O75':
      return 'bg-gold-8';
    case 'O80':
      return 'bg-gold-9';
  }
};

export const rankSwitchLabel = (points: number) => {
  if (points <= 0) return 'punti per non retrocedere';
  else return 'punti per essere promosso';
};

export const getRankCategory = (s: number | null) => {
  if (s === null) return null;
  if (s === 1) return 'OPEN';
  if (s === 2) return 'LIM 2^ CAT';
  if (s === 3) return 'LIM 3^ CAT';
  if (s === 4) return 'LIM 4^ CAT';
};

export const getNextRankChange = () => {
  const today = new Date();
  if (today > new Date('2025-09-15')) {
    return 'fine anno a dicembre 2025';
  } else if (today > new Date('2025-07-11')) {
    return '15 settembre 2025';
  } else if (today > new Date('2025-06-10')) {
    return '11 luglio 2025';
  } else if (today > new Date('2025-04-11')) {
    return '10 giugno 2025';
  } else if (today > new Date('2025-02-10')) {
    return '11 aprile 2025';
  } else {
    return '10 febbraio 2025';
  }
};

export const getSuperSimulationNextRankChange = () => {
  const today = new Date();
  if (today > new Date('2025-09-15')) {
    return 'disattivato fino a fine stagione';
  } else if (today > new Date('2025-07-11')) {
    return 'fine anno a dicembre 2025';
  } else if (today > new Date('2025-06-10')) {
    return '15 settembre 2025';
  } else if (today > new Date('2025-04-11')) {
    return '11 luglio 2025';
  } else if (today > new Date('2025-02-10')) {
    return '10 giugno 2025';
  } else {
    return '11 aprile 2025';
  }
};
