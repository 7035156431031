import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call, callTk, clearCacheMap } from 'api';
import type { EndorsementCallType, EndorsementType, EndorseParamsType, UnendorseParamsType } from 'types/player';

export const endorsements = createAsyncThunk<EndorsementType[], number>(
  'fit-player-profiles/endorsements',
  async (fitPlayerId, { rejectWithValue }) => {
    const response = await callTk<EndorsementType[]>(
      `fit-player-profiles/${fitPlayerId}/endorsements`,
      { method: 'GET' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const endorse = createAsyncThunk<EndorsementCallType, EndorseParamsType>(
  'endorsements/endorse',
  async ({ fitPlayerId, endorsementTypeId }, { rejectWithValue, dispatch }) => {
    clearCacheMap();
    const response = await call<EndorsementCallType>(
      'endorsements',
      {
        method: 'POST',
        body: JSON.stringify({
          fit_player_profile_id: fitPlayerId,
          endorsement_type_id: endorsementTypeId,
        }),
      },
      (response) => {
        dispatch(endorsements(fitPlayerId));
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const unendorse = createAsyncThunk<EndorsementCallType, UnendorseParamsType>(
  'endorsements/unendorse',
  async ({ endorsementId, fitPlayerId }, { rejectWithValue, dispatch }) => {
    const response = await call<EndorsementCallType>(
      `endorsements/${endorsementId}`,
      { method: 'DELETE' },
      (response) => {
        dispatch(endorsements(fitPlayerId));
        return response;
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const endorsementsSlice = createSlice({
  name: 'endorsements',
  initialState: {
    error: false,
    loading: false,
    endorsements: null as EndorsementType[] | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(endorsements.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(endorsements.fulfilled, (state, action: PayloadAction<EndorsementType[]>) => {
      state.loading = false;
      state.endorsements = action.payload;
    });
    builder.addCase(endorsements.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(endorse.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(endorse.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(endorse.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(unendorse.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(unendorse.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(unendorse.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const endorsementsReducer = endorsementsSlice.reducer;
