import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import { getMarketObj } from 'store/marketNewOffer/marketNewOfferSlice';
import type { NewOfferCallType, NewOfferParamsType, OfferType } from 'types/market';

export const fetchMarketOffer = createAsyncThunk<OfferType | null, string | undefined>(
  'offers/fetchMarketOffer',
  async (offerId, { rejectWithValue }) => {
    if (offerId) {
      const response = await callTk<OfferType>(
        `offers/${offerId}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return null;
  },
);

export const editMarketOffer = createAsyncThunk<NewOfferCallType, NewOfferParamsType>(
  'offers/editMarketOffer',
  async ({ data, profile }, { rejectWithValue }) => {
    const offer = Object.assign({}, data, {
      masqueraded_type: profile.type,
      masqueraded_id: profile.id,
    });
    const response = await callTk<NewOfferCallType>(
      `offers/${offer.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(getMarketObj(offer)),
      },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const deleteOffer = createAsyncThunk<NewOfferCallType, number>(
  'offers/deleteOffer',
  async (offerId, { rejectWithValue }) => {
    const response = await callTk<NewOfferCallType>(
      `offers/${offerId}`,
      { method: 'DELETE' },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const marketEditOfferSlice = createSlice({
  name: 'marketEditOffer',
  initialState: {
    loading: true,
    error: false,
    offer: null as OfferType | null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMarketOffer.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchMarketOffer.fulfilled, (state, action: PayloadAction<OfferType | null>) => {
      state.loading = false;
      if (!action.payload) return;
      const images = action.payload.images.map((image) => ({
        ...image,
        _destroy: false,
      }));
      state.offer = { ...action.payload, images };
    });
    builder.addCase(fetchMarketOffer.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(editMarketOffer.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(editMarketOffer.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
      successToastMessage('Offerta modificata con successo.');
    });
    builder.addCase(editMarketOffer.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Errore nella modifica dell' offerta.");
    });
    builder.addCase(deleteOffer.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteOffer.fulfilled, (state) => {
      state.loading = false;
      successToastMessage('Offerta eliminata con successo.');

      window.location.href = '/market';
    });
    builder.addCase(deleteOffer.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Errore nella eliminazione dell' offerta.");
    });
  },
});

export const marketEditOfferReducer = marketEditOfferSlice.reducer;
