import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { CompetitionAdvancedSearchResultType } from 'types/competition';

export type CompetitionAdvancedSearchCallType = {
  page: number;
  results: CompetitionAdvancedSearchResultType[];
  results_numbers: number;
  total_pages: number;
};

export type FiltersType = {
  age_category: string;
  championship: string;
  date_from: Date;
  date_to: Date;
  gender: string;
  query: string;
  region: string;
  province: string[];
  status: string;
};

export const fetch = createAsyncThunk<CompetitionAdvancedSearchCallType, FiltersType>(
  'competitions/advanced-search/fetch',
  async (filters, { rejectWithValue }) => {
    const response = await callTk<CompetitionAdvancedSearchCallType>(
      'competitions/advanced-search',
      {
        method: 'POST',
        body: JSON.stringify({
          ...filters,
        }),
      },
      (error) => rejectWithValue(error),
      true,
    );
    return response;
  },
);

export const competitionAdvancedSearchSlice = createSlice({
  name: 'competitionAdvancedSearch',
  initialState: {
    loading: false,
    error: false,
    data: {
      page: 1,
      total_pages: 0,
      results: [] as CompetitionAdvancedSearchResultType[],
      results_numbers: 0,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<CompetitionAdvancedSearchCallType>) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetch.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const competitionAdvancedSearchReducer = competitionAdvancedSearchSlice.reducer;
