import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { errorToastMessage, successToastMessage } from 'helpers/toaster';
import type { NewOfferCallType, NewOfferParamsType, OfferType } from 'types/market';

export const getMarketObj = (
  offer: OfferType & {
    masqueraded_id: number;
    masqueraded_type: string;
  },
) => ({
  title: offer.title,
  category_id: offer.category.id,
  second_hand: offer.second_hand,
  price: offer.price,
  images: offer.images,
  content: offer.content,
  masqueraded_id: offer.masqueraded_id,
  masqueraded_type: offer.masqueraded_type,
});

export const newOffer = createAsyncThunk<NewOfferCallType, NewOfferParamsType>(
  'offers/newOffer',
  async ({ data, profile }, { rejectWithValue }) => {
    const offer = Object.assign({}, data, {
      masqueraded_type: profile.type,
      masqueraded_id: profile.id,
    });
    const response = await callTk<NewOfferCallType>(
      'offers',
      {
        method: 'POST',
        body: JSON.stringify(getMarketObj(offer)),
      },

      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const marketNewOfferSlice = createSlice({
  name: 'marketNewOffer',
  initialState: {
    loading: false,
    error: false,
    success: false,
  },
  reducers: {
    newOfferClearData: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(newOffer.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(newOffer.fulfilled, (state, action: PayloadAction<NewOfferCallType>) => {
      state.loading = false;
      state.success = true;
      successToastMessage('Offerta inserita con successo.');

      window.location.href = `/offerte/${action.payload.offer.id}`;
    });
    builder.addCase(newOffer.rejected, (state) => {
      state.loading = false;
      state.error = true;
      errorToastMessage("Errore nell'inserimento offerta.");
    });
  },
});

export const { newOfferClearData } = marketNewOfferSlice.actions;
export const marketNewOfferReducer = marketNewOfferSlice.reducer;
