import React from 'react';

const NewTheme = React.lazy(() => import('./NewTheme'));

export const LoadCSS: React.FC = () => (
  <>
    <React.Suspense fallback={<></>}>
      <NewTheme />
    </React.Suspense>
  </>
);

export default LoadCSS;
