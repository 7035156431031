import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { successToastMessage } from 'helpers/toaster';
import { hideModal } from 'store/modals/modalsSlice';

export const invite = createAsyncThunk<{ success: boolean }, string>(
  'home/invitation/invite',
  async (emailToInvite, { rejectWithValue, dispatch }) => {
    const response = await callTk<{ success: boolean }>(
      'home/invitation',
      {
        method: 'POST',
        body: JSON.stringify({ email_to_invite: emailToInvite }),
      },
      (error) => rejectWithValue(error),
    );
    dispatch(hideModal('invitation-modal'));
    return response;
  },
);

export const invitationSlice = createSlice({
  name: 'invitation',
  initialState: {
    error: false,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(invite.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(invite.fulfilled, (state) => {
      state.loading = false;
      successToastMessage('Invito inviato con successo.');
    });
    builder.addCase(invite.rejected, (state) => {
      state.loading = false;
      state.error = true;
      //TODO manage error
    });
  },
});

export const invitationReducer = invitationSlice.reducer;
