import type { ReactNode } from 'react';
import { useState, useEffect } from 'react';
import Analytics from 'ui/design-base/Analytics';
import { mapRankValue, mapValueRank } from './ranks';
import type { Rank } from 'types/player';
import { DefaultWebViewOptions, InAppBrowser } from '@capacitor/inappbrowser';
import { Capacitor } from '@capacitor/core';

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
  children: ReactNode;
};

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition ? wrapper(children) : children;

type ContainerWrapperProps = {
  children: ReactNode;
};

export const ContainerWrapper = ({ children }: ContainerWrapperProps) => (
  <div className='external-container'>
    <div className='internal-container'>{children}</div>
  </div>
);

export const openInExternalUrl = async (url: string | null) => {
  if (url) {
    if (Capacitor.isNativePlatform()) {
      await InAppBrowser.openInWebView({
        url: url,
        options: DefaultWebViewOptions,
      });
    } else {
      window.open(url, '_blank')?.focus();
    }
  }
};

// Hook
const cachedScripts: string[] = [];
export const useScript = (src: string) => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        });
      } else {
        cachedScripts.push(src);

        // Create script
        const script = document.createElement('script');
        script.src = src;
        script.async = true;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();

          setState({
            loaded: true,
            error: true,
          });
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        // Add script to document body
        document.body.appendChild(script);

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad);
          script.removeEventListener('error', onScriptError);
        };
      }
    },
    [src], // Only re-run effect if script src changes
  );

  return [state.loaded, state.error];
};

export const delay = (timer: number) =>
  new Promise<void>((resolve) => {
    timer = timer || 100;
    setTimeout(() => {
      resolve();
    }, timer);
  });

export const reducer = (state: any, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  return { ...state, [type]: payload };
};

const testWebP: () => Promise<boolean> = () =>
  new Promise((res) => {
    const webP = new Image();
    webP.src =
      'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    webP.onload = webP.onerror = () => {
      res(webP.height === 2);
    };
  });

let isSupported: boolean;
export const isWebPSupported: any = async () => {
  if (isSupported === undefined) {
    isSupported = await testWebP();
  }
  return isSupported;
};

export const isPadel = (): boolean => import.meta.env.VITE_APPLICATION_TYPE === 'padel';

export const encodeToUrlQueryParameters = (params: Record<string, string | number | boolean | undefined | null>) =>
  Object.keys(params)
    .filter((key) => params[key])
    .sort()
    .map((key) => `${encodeURIComponent(key)}=${params[key] ? encodeURIComponent(params[key]) : ''}`)
    .join('&');

export const openLandingPageMembership = () => {
  Analytics.sendEvent({
    category: 'TT Club',
    action: 'Click Landing TT Club',
    label: 'Button TT Club',
  });
  const url = 'https://membership.tennistalker.it/jointtclub';
  openInExternalUrl(url);
};

export const openPaymentPageMembership = () => {
  Analytics.sendEvent({
    category: 'TT Club',
    action: 'Click Payment TT Club',
    label: 'Button Buy TT Club',
  });
  const url =
    'https://shop.tennistalker.it/tools/recurring/checkout_link?magic=eyJpdGVtcyI6IFt7ImlkIjogNDE5MjE1MDkzNTk3ODAsICJxdWFudGl0eSI6IDEsICJzZWxsaW5nX3BsYW4iOiAxMjM1NzE0MjEyLCAic2VsbGluZ19wbGFuX2dyb3VwX2lkIjogMzE1MDk3MjUyfV19&store_id=163085';
  window.open(url, '_blank')?.focus();
};

export const openMagazine = () => {
  Analytics.sendEvent({
    category: 'TT Magazine',
    action: 'Click Magazine',
    label: 'Button Magazine',
  });
  const url = 'https://magazine.tennistalker.it';
  window.open(url, '_blank')?.focus();
};

export const openPostSellPageMembership = () => {
  Analytics.sendEvent({
    category: 'TT Club',
    action: 'Click Post Sell TT Club',
    label: 'Button Post Sell TT Club',
  });
  const url = 'https://tennistalker41673.ac-page.com/ttclubsito';
  openInExternalUrl(url);
};

export const openMembershipSubscriptionManagementPage = () => {
  Analytics.sendEvent({
    category: 'TT Club',
    action: 'Click Subscription Management TT Club',
    label: 'Button Subscription Management TT Club',
  });
  const url = 'https://shop.tennistalker.it/tools/recurring/get-subscription-access';
  openInExternalUrl(url);
};

export const getPreviousRank: (rank: Rank | undefined) => Rank | '-' = (rank) => {
  if (!rank) return '-';
  const rankValue = mapRankValue[rank];
  if (rankValue > 1) return mapValueRank[rankValue - 1] as Rank;
  return rank;
};

export const getNextRank: (rank: Rank | undefined) => Rank | '-' = (rank) => {
  if (!rank) return '-';
  const rankValue = mapRankValue[rank];
  if (rankValue < 40) return mapValueRank[rankValue + 1] as Rank;
  return rank;
};

export const getTweCode: (date: Date) => string = (date) => {
  const month = date.getMonth() + 1;
  if (month === 1) return 'TWEVIP27HBH';
  if (month === 2) return 'TWEVIP54HSI';
  if (month === 3) return 'TWEVIP89KFD';
  if (month === 4) return 'TWEVIP74HXE';
  if (month === 5) return 'TWEVIP9IG45';
  if (month === 6) return 'TWEVIP0I23H';
  if (month === 7) return 'TWEVIP5G12J';
  if (month === 8) return 'TWEVIP9ASVJ';
  if (month === 9) return 'TWEVIPP6V51';
  if (month === 10) return 'TWEHSBJNDX4';
  if (month === 11) return 'TWEVIP87HBD';
  if (month === 12) return 'TWEVIP84HSV';
  return '';
};

export enum MatchFilterTypes {
  ALL = 'ALL',
  WINS = 'WINS',
  LOSSES = 'LOSSES',
  DOUBLES = 'DOUBLES',
  SINGLE_WINS = 'SINGLE_WINS',
  SINGLE_LOSSES = 'SINGLE_LOSSES',
  NAME = 'NAME',
  IS_IN_BEST_MATCHES = 'IS_IN_BEST_MATCHES',
}

export const italianDateToJSDate = (dateString?: string) => {
  if (!dateString) return new Date();
  const [day, month, year] = dateString.split('/').map(Number); // Split and convert to numbers
  return new Date(year, month - 1, day); // Month is 0-indexed in JavaScript
};

export const racketBrands = [
  { id: 0, name: 'Babolat' },
  { id: 1, name: 'Wilson' },
  { id: 2, name: 'Head' },
  { id: 3, name: 'Prince' },
  { id: 4, name: 'Yonex' },
  { id: 5, name: 'Dunlop' },
  { id: 6, name: 'Pacific' },
  { id: 7, name: 'ProKennex' },
  { id: 8, name: 'Tecnifibre' },
  { id: 9, name: 'Volkl' },
  { id: 10, name: 'Gamma' },
  { id: 11, name: 'Snauwaert' },
  { id: 12, name: 'Angell' },
  { id: 13, name: 'Diadem' },
  { id: 14, name: 'Fischer' },
  { id: 15, name: 'Gosen' },
  { id: 16, name: 'Mantis' },
  { id: 17, name: 'Pacific' },
  { id: 18, name: 'Slazenger' },
  { id: 19, name: 'Lacoste' },
  { id: 99, name: 'Altro' },
];

export const shoesBrands = [
  { id: 0, name: 'Joma' },
  { id: 1, name: 'Asics' },
  { id: 2, name: 'Adidas' },
  { id: 3, name: 'Nike' },
  { id: 4, name: 'Babolat' },
  { id: 5, name: 'Head' },
  { id: 6, name: 'K-Swiss' },
  { id: 7, name: 'Lotto' },
  { id: 8, name: 'Mizuno' },
  { id: 9, name: 'Yonex' },
  { id: 10, name: 'Diadora' },
  { id: 11, name: 'New Balance' },
  { id: 12, name: 'Wilson' },
  { id: 13, name: 'Decathlon' },
  { id: 14, name: 'ON' },
  { id: 15, name: 'Lacoste' },
  { id: 99, name: 'Altro' },
];

export const clothingBrands = [
  { id: 21, name: 'Old School' },
  { id: 22, name: 'Yoxoi' },
  { id: 20, name: 'Double U' },
  { id: 0, name: 'Joma' },
  { id: 1, name: 'Asics' },
  { id: 2, name: 'Adidas' },
  { id: 3, name: 'Nike' },
  { id: 4, name: 'Babolat' },
  { id: 5, name: 'Head' },
  { id: 6, name: 'K-Swiss' },
  { id: 7, name: 'Lotto' },
  { id: 8, name: 'Mizuno' },
  { id: 9, name: 'Yonex' },
  { id: 10, name: 'Diadora' },
  { id: 11, name: 'New Balance' },
  { id: 12, name: 'Wilson' },
  { id: 13, name: 'Australian' },
  { id: 14, name: 'Ellesse' },
  { id: 15, name: 'Lacoste' },
  { id: 16, name: 'Hydrogen' },
  { id: 17, name: 'Uniqlo' },
  { id: 18, name: 'Sergio Tacchini' },
  { id: 19, name: 'Under Armour' },
  { id: 20, name: 'Emporio Armani' },
  { id: 21, name: 'Fila' },
  { id: 22, name: 'Le Coq Sportif' },
  { id: 23, name: 'ON' },
  { id: 99, name: 'Altro' },
];

export const getMaxDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 14);
  return date;
};

export const getBrandName = (type: string, id: number | string) => {
  if (typeof id === 'string') {
    id = parseInt(id);
  }
  if (type === 'racket') {
    const res = racketBrands.find((r) => r.id === id);
    return res && res.name;
  } else if (type === 'shoes') {
    const res = shoesBrands.find((r) => r.id === id);
    return res && res.name;
  } else {
    const res = clothingBrands.find((r) => r.id === id);
    return res && res.name;
  }
};
