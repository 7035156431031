import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { unsetCurrentUser } from 'helpers/authentication';

export const request = createAsyncThunk<{ success: boolean }, string>(
  'reset-password/request/request',
  async (email, { rejectWithValue }) => {
    const response = await callTk<{ success: boolean }>(
      'reset-password/request',
      { method: 'PUT', body: JSON.stringify({ email }) },
      (error) => rejectWithValue(error),
    );
    return response;
  },
);

export const requestPasswordResetSlice = createSlice({
  name: 'requestPasswordReset',
  initialState: {
    error: false,
    loading: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(request.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(request.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
      unsetCurrentUser();
    });
    builder.addCase(request.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const requestPasswordResetReducer = requestPasswordResetSlice.reducer;
