import { currentUser, isCurrentUserPremium } from 'helpers/authentication';
import { gaUA } from 'helpers/ga-ua';
import ReactGA from 'react-ga4';
import { onCLS, onLCP, onFCP, onTTFB } from 'web-vitals';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from '@capacitor/core';

const TRACKING_ID = gaUA() ?? '';

const init = () => {
  if (!Capacitor.isNativePlatform()) {
    ReactGA.initialize(TRACKING_ID);
    onCLS(sendToGoogleAnalytics);
    onLCP(sendToGoogleAnalytics);
    onFCP(sendToGoogleAnalytics);
    onTTFB(sendToGoogleAnalytics);
  }
};

export const setUserId = async () => {
  const user = currentUser();
  const userId = user.id;
  if (!Capacitor.isNativePlatform()) {
    ReactGA.gtag('set', 'user_properties', {
      user_id: userId,
      membership: isCurrentUserPremium(),
      player: !!user.player,
    });
  } else {
    await FirebaseAnalytics.setUserId({
      userId: userId.toString(),
    });
  }
};

const sendToGoogleAnalytics = ({ name, delta, id }: { name: string; delta: number; id: string }) => {
  sendEvent({
    category: 'Web Vitals',
    action: name,
    label: id,
    eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
    nonInteraction: true,
    transport: 'beacon',
  });
};

const sendEvent = async (payload: {
  action: string;
  category: string;
  label: string;
  eventValue?: number;
  nonInteraction?: boolean;
  transport?: 'beacon' | 'xhr' | 'image' | undefined;
}) => {
  if (!payload?.label) {
    payload.label = `other case: ${payload?.category} ${payload?.action}`;
  }
  if (!Capacitor.isNativePlatform()) {
    ReactGA.event(payload);
  } else {
    await FirebaseAnalytics.logEvent({ name: payload.action, params: payload });
  }
};

const sendPageview = async (path: string) => {
  if (!Capacitor.isNativePlatform()) {
    ReactGA.set({ page: path });
    ReactGA.send('pageview');
  } else {
    await FirebaseAnalytics.setCurrentScreen({
      screenName: path,
    });
  }
};

export default {
  init,
  sendEvent,
  sendPageview,
};
