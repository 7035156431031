import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import { currentUser, setCurrentUser } from 'helpers/authentication';
import type { FollowUnfollowParameters } from 'store/followUnfollow/followUnfollowSlice';
import { setFollowed, setUnfollowed } from 'store/followUnfollow/followUnfollowSlice';
import type { UserDetailType } from 'types/user';

const FOLLOWABLE_TYPE = 'user';

export const fetchPlayerUserProfile = createAsyncThunk<UserDetailType | undefined, number | string | undefined>(
  'fit-player-profiles/user-info/fetchPlayerUserProfile',
  async (playerId, { rejectWithValue }) => {
    if (playerId) {
      const response = await callTk<UserDetailType>(
        `fit-player-profiles/${playerId}/user-info`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return;
  },
);

export const fetchUserProfile = createAsyncThunk<UserDetailType | undefined, number | string | undefined>(
  'users/fetchUserProfile',
  async (userId, { rejectWithValue }) => {
    if (userId) {
      const response = await callTk<UserDetailType>(
        `users/${userId}`,
        { method: 'GET' },
        (error) => rejectWithValue(error),
        true,
      );
      return response;
    }
    return;
  },
);

export const acceptMarketCondition = createAsyncThunk<{ success: boolean }, void>(
  'users/market-condition-accepted/acceptMarketCondition',
  async (_, { rejectWithValue }) => {
    const response = await callTk<{ success: boolean }>('users/market-condition-accepted', { method: 'PUT' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    error: false,
    loading: false,
    user: undefined as UserDetailType | undefined,
  },
  reducers: {
    clearUserProfile: (state) => {
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlayerUserProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchPlayerUserProfile.fulfilled, (state, action: PayloadAction<UserDetailType | undefined>) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(fetchPlayerUserProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action: PayloadAction<UserDetailType | undefined>) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(fetchUserProfile.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(setFollowed, (state, action: PayloadAction<FollowUnfollowParameters>) => {
      const { id, type } = action.payload;
      if (type === FOLLOWABLE_TYPE && state.user && state.user.id === id) {
        state.user.followed = true;
      }
    });
    builder.addCase(setUnfollowed, (state, action: PayloadAction<FollowUnfollowParameters>) => {
      const { id, type } = action.payload;
      if (type === FOLLOWABLE_TYPE && state.user && state.user.id === id) {
        state.user.followed = false;
      }
    });
    builder.addCase(acceptMarketCondition.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(acceptMarketCondition.fulfilled, (state) => {
      state.loading = false;
      setCurrentUser({
        ...currentUser(),
        market_condition_accepted: true,
      });
    });
    builder.addCase(acceptMarketCondition.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clearUserProfile } = userProfileSlice.actions;
export const userPreviewReducer = userProfileSlice.reducer;
