import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callTk } from 'api';
import type { ClubShortType } from 'types/club';
import type { CompetitionShortType } from 'types/competition';
import type { PlayerDetailShortType } from 'types/player';
import type { ProfileFilterType, ProfilesCounterType, SearchCallType } from 'types/search';
import type { UserDetailShortType } from 'types/user';

export const search = createAsyncThunk<
  SearchCallType,
  {
    filter: 'players' | 'clubs' | 'other_profiles' | 'competitions';
    query: string;
    page: number;
    profileFilter?: string | null;
  }
>('search/search', async ({ filter, query, page, profileFilter }, { rejectWithValue }) => {
  const response = await callTk<SearchCallType>(
    `search/${filter}`,
    {
      method: 'POST',
      body: JSON.stringify({
        q: query,
        page,
        profile_filter: profileFilter,
      }),
    },
    (error) => rejectWithValue(error),
    true,
  );
  return response;
});

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    loading: false,
    error: false,

    currentFilter: 'players' as 'players' | 'clubs' | 'other_profiles' | 'competitions',
    results: [] as UserDetailShortType[] | ClubShortType[] | CompetitionShortType[] | PlayerDetailShortType[],
    totalPages: 0,

    profiles_counters: null as ProfilesCounterType | null,
    players: {
      page: 1,
      count: 0,
    },
    clubs: {
      page: 1,
      count: 0,
    },
    competitions: {
      page: 1,
      count: 0,
    },
    other_profiles: {
      page: 1,
      count: 0,
      profileFilter: 'all' as ProfileFilterType,
    },
  },
  reducers: {
    setSearchFilter: (state, action: PayloadAction<'players' | 'clubs' | 'other_profiles' | 'competitions'>) => {
      state.currentFilter = action.payload;
    },
    setProfileFilter: (state, action: PayloadAction<ProfileFilterType>) => {
      state.other_profiles.profileFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(search.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(search.fulfilled, (state, action: PayloadAction<SearchCallType>) => {
      state.loading = false;
      state.results = action.payload.results;
      state.totalPages = action.payload.total_pages;
      state.players.count = action.payload.results_numbers.players;
      state.clubs.count = action.payload.results_numbers.clubs;
      state.competitions.count = action.payload.results_numbers.competitions;
      state.other_profiles.count = action.payload.results_numbers.other_profiles;
      if (action.payload.profiles_counters) {
        state.profiles_counters = action.payload.profiles_counters;
      }
      // Using a type guard to narrow down state fields
      if (state.currentFilter === 'players') {
        state.players.page = action.payload.page;
        state.players.count = action.payload.results_numbers.players;
      } else if (state.currentFilter === 'clubs') {
        state.clubs.page = action.payload.page;
        state.clubs.count = action.payload.results_numbers.clubs;
      } else if (state.currentFilter === 'competitions') {
        state.competitions.page = action.payload.page;
        state.competitions.count = action.payload.results_numbers.competitions;
      } else if (state.currentFilter === 'other_profiles') {
        state.other_profiles.page = action.payload.page;
        state.other_profiles.count = action.payload.results_numbers.other_profiles;
      }
    });
    builder.addCase(search.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { setSearchFilter, setProfileFilter } = searchSlice.actions;
export const searchReducer = searchSlice.reducer;
