export const gaUA = () => {
  switch (import.meta.env.VITE_ENV) {
    case 'production':
      return import.meta.env.VITE_GOOGLE_ANALYTICS_UA_PROD;
    case 'staging':
      return import.meta.env.VITE_GOOGLE_ANALYTICS_UA_STAGING;
    default:
      return import.meta.env.VITE_GOOGLE_ANALYTICS_UA;
  }
};
